import React, { useState,useEffect } from "react";
import {
  getAmountDue,
  getAmountPaid,
  getStudentInvoice,
  getStudentLoans,
} from "../fee-payment/services";
import StudentFeeDetails from "./student-fee-details";

const INITIAL_STATE = {
  admno: null,
  invoideDetails: null,
  paidDetails: null,
  dueDetails: null,
};

const FeePayment = () => {
    const [state, setState] = useState(INITIAL_STATE);

    useEffect(() => {
      feeDetail()
    }, []);
    
    const feeDetail = async () => {
      const payload = {
        admno: localStorage.getItem("admission"),
      };
      const loan = await getStudentLoans({
        admno: localStorage.getItem("admission"),
        academicYear: "2022/2023",
      });
      const paidAmt = await getAmountPaid(payload);
      const amountDue = await getAmountDue(payload);
      const invoice = await getStudentInvoice(payload);
  
      setState((prevState) => ({
        ...prevState,
        invoice: invoice.data?.data,
        status: loan.data?.data?.hasLoan,
        loan: loan.data?.data?.amount,
        paidAmt: paidAmt?.data?.data,
        amountDue: amountDue?.data?.data,
        admno:localStorage.getItem("admission"),
      }));
    };

    const feeDetailsData = () => {
      const { admno, invoice, status, loan, paidAmt, amountDue } = state;
      return {
        admno,
        invoice,
        status,
        loan,
        paidAmt,
        amountDue,
      };
    };

    return (
    <>
       <div className="row">
           <div className="col-sm-1"></div>
           <div className="col-sm-10">
           <StudentFeeDetails
            details={feeDetailsData()}
           />
           </div>
       </div> 
    </>
  );
};

export default FeePayment;
