import axios from "api/axios";
import useUnitQuery from "./useUnitQuery";
import { useMemo } from "react";

export default function useCurrentStudent() {
  const query = useUnitQuery(() => axios.get("/api/users/me"));
  const me = useMemo(() => {
    const admission = query.data?.principal?.student?.admission;
    const fullName = admission ? `${admission.firstname} ${admission.middlename} ${admission.surname}` : "";
    return {
      ok: !!query.data?.principal?.student,
      user: query.data?.principal,
      student: query.data?.principal?.student,
      admission: admission,
      program: admission?.program,
      department: admission?.program?.department,
      campus: admission?.campus,
      fullName
    };
  }, [query.data]);
  return me;
}
