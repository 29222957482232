import React, { useState, useEffect } from "react";
import { getStudentCarryRegistraionResults, carryRegistration, checkRegistrationStatus } from "./services";
import BaseTable from "helpers/BaseTable";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import BaseSelect from "helpers/Select";
import Grid from "@material-ui/core/Grid";
import BaseButton from "helpers/BaseButton";
import Fetcher from "helpers/Fetcher";


const APIS = {
   ACADEMIC_YEARS_API: "/api/academic_year/all",
   PROGRAMMES: "/api/program/all?size=100",
   CAMPUSES: "/api/lookup/campus/list",
   INTAKE: "/api/intake/all",
   SEMESTER_LIST_API: "/api/semester/all",
   STUDENT_CARRY_MODULES: "/api/carry-registrations/modules",
};

const SearchYears = [
   { name: "1", id: "1" },
   { name: "2", id: "2" },
   { name: "3", id: "3" },
];


const INITIAL_STATE = {

};

const FORM_INITIAL_STATE = {
   year: "",
   semesterId: "",
   moduleId: "",
   academicYear: "",
};

const FORM_VALIDATION = Yup.object().shape({
   moduleId: Yup.string().required("Please select module"),
});
const CarryRegistration = () => {
   const [state, setState] = useState(INITIAL_STATE);

   const CARRY_REGISTRATION_DETAILS_DATA = [
      { value: "number", label: "S/N" },
      { value: "yearOfStudy", label: "Year of Study" },
      { value: "semester", label: "Semester" },
      { value: "academicYear", label: "Academic year" },
      // { value: "actions", label: "Remove" },
   ];

   useEffect(() => {
      carryRegistrationDetails();
      registrationStatus();
   }, []);

   const carryRegistrationDetails = async () => {
      const res = await getStudentCarryRegistraionResults(localStorage.getItem("admission"),);
      setState((prevState) => ({
         ...prevState,
         isDetailDialogOpen: true,
         // student_data: student,
         // registration_data: res.data.data,
         carry_data: generateCarryRegistrationData(res?.data?.data),
      }));
   };

   const generateCarryRegistrationData = (data) => {
      return data.map((item, idx) => ({
         ...item,
         number: idx + 1,
         semester: item.semester.id,
         academicYear: item.academicYear.academicYear,
         yearOfStudy: item.yearOfStudy,
      }));
   };

   const registrationStatus = async () => {
      const payload = {
         admno: localStorage.getItem("admission"),
      };

      const regStatus = await checkRegistrationStatus(payload);
      if (
         regStatus.data &&
         regStatus.data.data.isRegistered === 0 &&
         regStatus.data.data.isEligibleForReg === 1
      ) {
         setState((prevState) => ({
            ...prevState,
            academicYearId: regStatus.data.data.academicYearId,
            academicYear: regStatus.data.data.academicYear,
            semester: regStatus.data.data.semester,
            yearOfStudy: regStatus.data.data.yearOfStudy,
            isRegistered: regStatus.data.data.isRegistered,
            isEligibleForReg: regStatus.data.data.isEligibleForReg,
            requiredFeePercentage: regStatus.data.data.requiredFeePercentage,
            paidFeePercentage: regStatus.data.data.paidFeePercentage,
            lastSemExamRemark: regStatus.data.data.lastSemExamRemark,
            controlNumber: regStatus.data.data.controlNumber,
         }));
      } else if (
         regStatus.data &&
         regStatus.data.data.isRegistered === 0 &&
         regStatus.data.data.isEligibleForReg === 0
      ) {
         setState((prevState) => ({
            ...prevState,
            academicYearId: regStatus.data.data.academicYearId,
            academicYear: regStatus.data.data.academicYear,
            semester: regStatus.data.data.semester,
            yearOfStudy: regStatus.data.data.yearOfStudy,
            isRegistered: regStatus.data.data.isRegistered,
            isEligibleForReg: regStatus.data.data.isEligibleForReg,
            requiredFeePercentage: regStatus.data.data.requiredFeePercentage,
            paidFeePercentage: regStatus.data.data.paidFeePercentage,
            lastSemExamRemark: regStatus.data.data.lastSemExamRemark,
            controlNumber: regStatus.data.data.controlNumber,
         }));
      } else if (regStatus.data) {
         setState((prevState) => ({
            ...prevState,
            academicYearId: regStatus.data.data.academicYearId,
            academicYear: regStatus.data.data.academicYear,
            semester: regStatus.data.data.semester,
            isRegistered: regStatus.data.data.isRegistered,
         }));
      }
   };

   const registerCarry = async (helpers, values) => {
      let payload = {
         admissionNo: localStorage.getItem("admission"),
         academicYearId: state.academicYearId,
         semesterId: state.semester,
         yearOfStudy: state.yearOfStudy,
         moduleId: values.moduleId,
      };
      console.log(payload);
      const res = await carryRegistration(payload);
      if (res.data.stsCode === "0000") {
         setState((prevState) => ({
            ...prevState,
         }));
         helpers.resetForm();
         window.location.reload();
      } else {
         helpers.resetForm();
      }
   };


   return (
      <>
         Carry Registrations Details
         <BaseTable headers={CARRY_REGISTRATION_DETAILS_DATA} items={state.carry_data}></BaseTable>
         <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-10">
               <br />
               <br />
               Carry Modules
               <Formik
                  initialValues={FORM_INITIAL_STATE}
                  validationSchema={FORM_VALIDATION}
                  onSubmit={(helpers, values) => registerCarry(values, helpers)}
               >
                  {({ isValid, dirty }) => (
                     <Form className="form-inline">
                        <Grid container spacing={2}>
                           {/* <Grid item xs={12}>
                    <BaseTextField name="reason" label="Enter Reason" />
                  </Grid> */}
                           <Grid item xs={12}>
                              <Fetcher
                                 api={`${APIS.STUDENT_CARRY_MODULES}/${localStorage.getItem("admission")}`}
                                 render={(response) => (
                                    <BaseSelect
                                       label="Select Module"
                                       name="moduleId"
                                       options={response.json}
                                       displayName="moduleName"
                                       itemValue="moduleId"
                                    />
                                 )}
                              />
                           </Grid>

                           <Grid item xs={4}>
                              <Grid item>
                                 <BaseButton disabled={!dirty || !isValid} type="submit">
                                    Register Carry
                                 </BaseButton>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Form>
                  )}
               </Formik>
            </div>
         </div>
      </>
   );
};

export default CarryRegistration;