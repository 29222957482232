import axios from "api/axios";

export const HOSTEL_API = {
  SEARCH_ROOMS_API: "/api/hostel/rooms",
  HOSTEL: "/api/hostel",
};

export const getAllocations = (params) =>
  axios.get(`${HOSTEL_API.HOSTEL}/allocations/me`, { params });
export const getAllocation = ({ id, ...params }) =>
  axios.get(`${HOSTEL_API.HOSTEL}/allocations/me/${id}`, { params });

export const createAllocation = (body) => {
  return axios.post(`${HOSTEL_API.HOSTEL}/allocations/me/create`, body);
};
