import axios from "api/axios";

const SEARCH_FEE = "/api/student/search";
const SEARCH_BY_CONTROL_NO = "/api/tia_receipt/fetch_by_control_no";
const STUDENT_INVOICE = "/api/finance/invoices/total_amount"; //debited amount
const SEARCH_LOAN_CURRENT_ACYR = "/api/student_loans/current_acyear/verify"; //current ac year
const PAID_AMOUNT = "/api/fee_payment/total_amount";
const STUDENT_AMOUNT_DUE = "/api/finance/invoices/amount_due"; //anachodaiwa (total amount due)
const STUDENT_INVOICE_DETAILS = "/api/finance/invoices/fetch"; // debited amount details/invoices
const PAID_AMOUNT_DETAILS = "/api/fee_payment/fetch"; //details za malipo
const STUDENT_DUE_DETAILS = "/api/student/amount_due"; //baada ya kutoa total amount due na hesb amount due
const LOAN_DUE = "/api/student_loans/amount_due"; //tunawadai bodi (HESLB amount due)
const PRINT_STUDENT_INVOICE = "/api/finance/invoices/print/";
const REUSE_BILL = "/api/gepg2/bill_reuse";

//SEARCH BY CONTROL NUMBER
const searchByControlNumber = async (payload) => {
  return await axios.post(`${SEARCH_BY_CONTROL_NO}`, payload);
};

const searchStudents = async (params) => {
  return await axios.get(`${SEARCH_FEE}`, { params });
};

//INVOICE
const getStudentInvoice = async (payload) => {
  return await axios.post(`${STUDENT_INVOICE}`, payload);
};

//LOAN
const getStudentLoans = async (payload) => {
  return await axios.post(`${SEARCH_LOAN_CURRENT_ACYR}`, payload);
};

//PAYMENT
const getAmountPaid = async (payload) => {
  return await axios.post(`${PAID_AMOUNT}`, payload);
};

const getAmountDue = async (payload) => {
  return await axios.post(`${STUDENT_AMOUNT_DUE}`, payload);
};

const getInvoiceDetails = async (payload) => {
  return await axios.post(`${STUDENT_INVOICE_DETAILS}`, payload);
};

const getPaymentDetails = async (payload) => {
  return await axios.post(`${PAID_AMOUNT_DETAILS}`, payload);
};

const getDueDetails = async (payload) => {
  return await axios.post(`${STUDENT_DUE_DETAILS}`, payload);
};

const getHelsbDueAmount = async (payload) => {
  return await axios.post(`${LOAN_DUE}`, payload);
};

const printStudentInvoice = async (payload) => {
  return await axios.get(`${PRINT_STUDENT_INVOICE}${payload}`);
};

const reuseBillControlNumber = async (invoiceNo) => {
  return await axios.post(REUSE_BILL, { billId: invoiceNo });
};


export {
  getAmountDue,
  getAmountPaid,
  getDueDetails,
  getHelsbDueAmount,
  getInvoiceDetails,
  getPaymentDetails,
  getStudentInvoice,
  getStudentLoans,
  PRINT_STUDENT_INVOICE,
  searchByControlNumber,
  searchStudents,
  printStudentInvoice,
  reuseBillControlNumber,
};
