import React, { useEffect, useState } from "react"
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider'
import { Link } from "react-router-dom";

import { Route } from 'react-router-dom'

import Header from 'helpers/Header/Header'

import Alevel from './alevel'
import Diploma from './diploma'
import PostEducation from './post-education'
import PrimaryOlevel from './primary-olevel'

import './education.css'

const Home = () => {
  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card">
          <div className="card-header">
            <Header title=" Education Background" />
          </div>
          <div className="card-body exam-card">
            <div className="block">
              <Grid container>
                <Grid item xs={2}>
                  <Paper elevation={0} style={{height: '100%', borderRight: '1px solid #ccc'}}>
                    <Tabs
                      value={selectedTab}
                      orientation="vertical"
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab component={Link} to="/education-background" label="Primary and Olevel"   />
                      <Divider />
                      <Tab component={Link} to="/education-background/alevel"  label="Alevel"  />
                      <Divider />
                      <Tab component={Link} to="/education-background/diploma"  label="Diploma"  />
                      <Divider />
                      <Tab component={Link} to="/education-background/post-education"  label="Post Education"  />
                      <Divider />

                    </Tabs>
                  </Paper>
                </Grid>
                <Grid item xs={10} style={{ padding: '15px' }}>
                  <Route exact path="/education-background" component={PrimaryOlevel} />
                  <Route path="/education-background/alevel" component={Alevel} />
                  <Route path="/education-background/diploma" component={Diploma} />
                  <Route path="/education-background/post-education" component={PostEducation} />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
