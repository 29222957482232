import Home from "../home";

const programRoutes = [
  {
    path: "/program-modules",
    exact: true,
    component: Home,
  },
];

export default programRoutes;