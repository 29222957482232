import React, { useState,useEffect } from "react";
import * as Yup from 'yup'
import { Formik, Form } from 'formik'

import BaseTextField from 'helpers/TextField'
import Grid from '@material-ui/core/Grid'
import { API } from './services'

const FORM_INITIAL_STATE = {
    nhif_no: "",
  }
  
  const FORM_VALIDATION = Yup.object().shape({
    nhif_no: Yup.string().required("Please enter NHIF No"),
 })

const ValidateNhifCard = () => {
    const [state, setState] = useState(FORM_INITIAL_STATE);

    const handleSearch = (helpers, values) =>{
      //   addStudent(values).then((res) => {
      //     if (res.status === 200) {
      //       if (res.data.stsCode === "0000") {
      //         setState(prevState => ({
      //           ...prevState,
      //           admno: values.value,
      //           searchValues: values,
      //           students: data,
      //         }));
      //         helpers.resetForm()
      //       }
      //     } else {
      //       return
      //     }
      //   });
      }

    return (
      <>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
        <h5>VALIDATE NHIF CARD</h5>
        <br />
          <Formik initialValues={FORM_INITIAL_STATE}
            validationSchema={FORM_VALIDATION} onSubmit={(helpers, values) =>
              handleSearch(values, helpers)}>
            {({ isValid, dirty }) => (
              <Form className="form-inline">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <BaseTextField name="nhif_no" label="Enter NHIF No" />
                </Grid>
              </Grid>
                <button
                  style={{marginTop: '20px'}}
                  disabled={!dirty || !isValid}
                  className="btn btn-round  btn-md btn-primary form-inline-button"
                  type="submit" name="submit"
                >
                  <i className="fa fa-plus-circle"></i> Validate
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      </>
  );
};

export default ValidateNhifCard;