import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "helpers/BaseTable/pagination";
import PropTypes from "prop-types";
import { getSlots } from "helpers/Slots";
import { styled, withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      borderLeft: ({ showBorders }) =>
        showBorders ? "1px solid rgba(224, 224, 224, 1)" : null,
    },
    "& .MuiTableCell-head": {
      height: "65px",
      background: "#f2f2f2",
    },
    "& .MuiTableRow-root": {
      "&:nth-child(even)": {
        backgroundColor: ({ stripped }) => (stripped ? "#f2f2f2" : null),
      },
    },
  },
  thead: {
    ".MuiTableHead-root": {
      "& .MuiTableRow-head": {
        height: "65px",
        backgroundColor: "#f4f4f4",
      },
    },
  },
}));

const StyledTableRow = withStyles((_) => ({
  root: {
    minHeight: "20px",
    height: "30px",
  },
}))(TableRow);

export const StyledTableCell = withStyles((_) => ({
  root: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
}))(TableCell);

export const BaseTable = ({
  items,
  headers,
  itemKey,
  children,
  rowConfig,
  showPagination = false,
  onPagination,
  rows = [5, 10, 20, 50, 100, 200],
  params,
  elevate = true,
  stripped = false,
  showBorders = false,
  rowHeight = "35px",
  size = "small",
  ...tableConfig
}) => {
  const _params = { showBorders, stripped, rowHeight };
  const classes = useStyles(_params);
  const [actions] = getSlots(["actions"], children);
  const $headers = useMemo(() => {
    const _h = headers.map((c) => {
      const value = c.value ?? c.name;
      return {
        ...c,
        value,
        columnPath: (v) =>
          typeof value == "function"
            ? value(v)
            : value?.split(".").reduce((r, c) => r?.[c], v),
      };
    });
    return _h;
  }, [headers]);

  const rowConfigs = {
    ...rowConfig,
    hover: true,
  };

  const dynamicRenderer = (row, column) => {
    if (column.value === "actions") {
      const props = { ...row };
      return children(props);
    } else {
      return column.columnPath?.(row);
    }
  };

  const renderHeader = (header, index) => {
    return showBorders ? (
      <StyledTableCell key={index}>{header.label}</StyledTableCell>
    ) : (
      <TableCell key={index}>{header.label}</TableCell>
    );
  };

  return (
    <>
      <TableContainer component={elevate ? Paper : null}>
        <Table
          {...tableConfig}
          size={size}
          className={classes.root}
          aria-label="simple table"
        >
          <TableHead style={{ height: "60px", backgroundColor: "#f4f4f4" }}>
            <TableRow {...rowConfigs}>{headers.map(renderHeader)}</TableRow>
          </TableHead>
          <TableBody>
            {items && items.length > 0 ? (
              items.map((item, i) => (
                <StyledTableRow
                  key={itemKey ? item[itemKey] : i}
                  {...rowConfigs}
                >
                  {$headers.map((column) =>
                    showBorders ? (
                      <StyledTableCell key={column.value}>
                        {dynamicRenderer(item, column)}
                      </StyledTableCell>
                    ) : (
                      <TableCell key={column.value}>
                        {dynamicRenderer(item, column)}
                      </TableCell>
                    )
                  )}
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow {...rowConfigs}>
                <TableCell
                  colSpan={100}
                  style={{ height: "50px", textAlign: "center" }}
                >
                  No Data
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        {showPagination && (
          <TablePagination
            rows={rows}
            params={params}
            onPagination={onPagination}
          />
        )}
      </TableContainer>
    </>
  );
};

BaseTable.defaultProps = {};

BaseTable.propTypes = {
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  rowConfig: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default BaseTable;
