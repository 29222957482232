import Home from "../home";

const examinationRoutes = [
  {
    path: "/examination-results",
    exact: true,
    component: Home,
  },
];

export default examinationRoutes;