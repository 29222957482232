import axios from "api/axios";
const ALL_STATUS_TCU = "/api/tcu/check_selection_Status";
// const SEARCH_CARD = "/api/nhif/card-verification";
const VERIFY_NHIF_CARD = "/api/nhif/verify";
const NHIF_REGISTRATION = "/api/nhif/registration";
const NHIF_CARD_APPLICATION = "/api/nhif/card-application";
const NHIF_RENEW_CARD = "/api/nhif/card-renew";
const VIEW_CONTROL_NUMBER = "/api/nhif/getStatus";
const VIEW_PAYMENT_STATUS = "/api/nhif/getStatus";
const NHIF_GENERAL_STATUS = "/api/nhif/getStatus";

const searchStudents = async (params) => {
  return await axios.get(`${ALL_STATUS_TCU}`, { params });
};

const getAllStatusTcu = async (payload) => {
  return await axios.post(`${ALL_STATUS_TCU}`, payload);
};

const appliedProgrammes = async (payload) => {
  return await axios.get(`${ALL_STATUS_TCU}${payload}`);
};

// const searchCard = async (params) => {
//   return await axios.get(`${SEARCH_CARD}`, { params });
// };
const verifyCard = async (params) => {
  return await axios.post(`${VERIFY_NHIF_CARD}`, params);
};

const nhifRegistration = async (payload) => {
  return await axios.post(`${NHIF_REGISTRATION}`, payload);
};

const nhifCardApplication = async (payload) => {
  return await axios.post(`${NHIF_CARD_APPLICATION}`, payload);
};

const nhifRenewCard = async (payload) => {
  return await axios.post(`${NHIF_RENEW_CARD}`, payload);
};

const viewControlNumber = async (payload) => {
  return await axios.post(`${VIEW_CONTROL_NUMBER}`, payload);
};

const viewPaymentStatus = async (payload) => {
  return await axios.post(`${VIEW_PAYMENT_STATUS}`, payload);
};

const nhifGeneralStatus = async (params) => {
  return await axios.get(`${NHIF_GENERAL_STATUS}`, { params });
};

export {
  searchStudents,
  getAllStatusTcu,
  appliedProgrammes,
  // searchCard,
  verifyCard,
  nhifRegistration,
  nhifCardApplication,
  nhifRenewCard,
  viewControlNumber,
  viewPaymentStatus,
  nhifGeneralStatus,
};
