import { Alert } from "@material-ui/lab";
import { useMemo } from "react"

const findString = (prevKey, obj) => {
  if (!obj) return null;
  let keys = Object.keys(obj);
  if (!keys.length) return null;
  const currKey = keys.filter(k => obj[k] !== undefined)[0];
  let item = obj[currKey];
  if (typeof item == 'string' || item == null) return `${prevKey} ${item}`;
  return findString(`${prevKey}.${currKey}`, item);
}
export default function FormikError({ errors }) {
  const err = useMemo(() => {
    return findString("", errors);
  }, [errors])
  if (!err) return null;
  return (
    <Alert severity="error">{err}</Alert>
  )
}
