import React, { Component } from "react";

class DisplayCollege extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  render() {
    return (
      <div style={{ paddingTop: "5px" }}>
        
          {this.state.stage === 6 ? (
            <>
              {this.props.college_data.map((option, i) => (
                <div key={i}>
                  {i === 0 ? (
                    <div>
                        {option.institutionName.toUpperCase()} , PROGRAM:
                        {option.program.toUpperCase()}, YEAR:{" "}
                        {option.completedYear} , REGNO:{" "}
                        {option.registrationNumber}, GPA: {option.gpa}
                   
                        {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                   </div>
                  ) : (
                   <div>
                        {option.institutionName.toUpperCase()} , PROGRAM:
                        {option.program.toUpperCase()}, YEAR:{" "}
                        {option.completedYear} , REGNO:{" "}
                        {option.registrationNumber}, GPA: {option.gpa}
                    
                        {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                  </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {" "}
              {this.props.college_data.map((option, i) => (
                <div key={i}>
                  {i === 0 ? (
                   <div>
                        {option.institutionName.toUpperCase()} , PROGRAM:
                        {option.program.toUpperCase()}, YEAR:{" "}
                        {option.completedYear} , REGNO:{" "}
                        {option.registrationNumber}, GPA: {option.gpa}
                    
                        {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                   </div>
                  ) : (
                    <div>
                        {option.institutionName.toUpperCase()} , PROGRAM:
                        {option.program.toUpperCase()}, YEAR:{" "}
                        {option.completedYear} , REGNO:{" "}
                        {option.registrationNumber}, GPA: {option.gpa}
                     
                        {option.results.length > 0 ? (
                          <table
                            className="table table-bordered table-striped"
                            key={i + "table"}
                          >
                            <tbody key={i + "tablebody"}>
                              <tr key={i + "tableHeader"}>
                                <td key={0 + i}>COURSE</td>
                                <td key={1 + i}>PASSMARK</td>
                              </tr>

                              {option.results.map((opt, j) => (
                                <tr key={j + i}>
                                  <td key={j + i + 0}>{opt.module}</td>
                                  <td key={j + i + 1}>{opt.passmark}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : null}
                   </div>
                  )}
                </div>
              ))}
            </>
          )}
       
      </div>
    );
  }
}

export default DisplayCollege;
