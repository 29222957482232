import { Button, Grid, TextField } from "@material-ui/core";
import PageContent from "components/PageContent";
import FetcherAutoComplete from "components/FetcherAutoComplete";
import { Form, Formik } from "formik";
import { HOSTEL_API, createAllocation } from "../service";
import BaseTextField from "helpers/TextField";
import BaseSelect from "helpers/Select";
import FileUploadInput from "helpers/FileUploadInput";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import FormikErrors from "helpers/FormikError";
import DateTimePicker from "helpers/DateTimePicker";

const FORM_VALIDATION = Yup.object().shape({
  start_date: Yup.string().required("Please enter start date"),
  end_date: Yup.string().required("Please enter end date"),
  room: Yup.object().required("Please select room").typeError("room is required"),
});
const INITIAL_STATE = {};

export default function RequestAbsence() {
  const history = useHistory();
  const handleSubmit = async (values) => {
    const result = await createAllocation({
      start_date: values.start_date,
      end_date: values.end_date,
      room_id: values.room?.id,
    });
    if (result.data?.status == 201) history.push("/hostel");
  };
  return (
    <PageContent title={"Hostel Allocation Request"}>
      <Formik
        validationSchema={FORM_VALIDATION}
        initialValues={INITIAL_STATE}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty, errors }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormikErrors errors={errors} />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  fullWidth
                  format="yyyy-MM-dd"
                  outputFormat="yyyy-MM-dd HH:mm:ss"
                  disableClock={true}
                  name="start_date"
                  label="Start Date"
                />
              </Grid>
              <Grid item xs={6}>
                <DateTimePicker
                  fullWidth
                  format="yyyy-MM-dd"
                  outputFormat="yyyy-MM-dd HH:mm:ss"
                  disableClock={true}
                  name="end_date"
                  label="End Date"
                />
              </Grid>
              <Grid item xs={12}>
                <FetcherAutoComplete
                  keyColumn="id"
                  label="Search Room"
                  labelTemplate="{name} ~ {room_type} {room_number}"
                  name="room"
                  onFetch={(axios, value) =>
                    axios.get(HOSTEL_API.SEARCH_ROOMS_API, {
                      params: { name: value },
                    })
                  }
                />
              </Grid>
              <Grid item container justifyContent="flex-end">
                <Button type="submit" variant="contained">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </PageContent>
  );
}
