import React from "react"

import InvoiceDetailTable from './invoice-detail-table'

const ViewPaidAmtDetails = ({items}) => {

  const PAID_DETAIL_HEADERS = [
    {id: 1, name: 'number', label: 'S/N'},
    {id: 2, name: 'datePaid', label: 'DATE PAID'},
    {id: 3, name: 'tiaReceiptNo', label: 'RECEIPT. NO'},
    {id: 4, name: 'controlNo', label: 'CONTROL NO'},
    {id: 6, name: 'gepgReceiptNo', label: 'GePG RECEIPT'},
    {id: 5, name: 'amountPaid', label: 'AMOUNT PAID'},
    {id: 7, name: 'currency', label: 'CURRENCY'},
  ]

  return (
    <>
      <InvoiceDetailTable headers={PAID_DETAIL_HEADERS} items={items} />
      </>
  )
}
export default ViewPaidAmtDetails
