import React, { Component } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";

class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        {
          title: "Welcome to Student Information Management System",
          link: "#",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Welcome to Student Information Management System"
              breadcrumbItems={this.state.breadcrumbItems}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default WelcomePage;
