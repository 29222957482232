import React, { Component } from "react";
import { Container, Alert } from "reactstrap";
import EditBankDetails from "./EditBankDetails";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import BankDetails from "./BankDetails";
import { getBankDetails } from "./services";

class Bank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{ title: "Dashboard", link: "#" }],
      data: [],
      firstname: "",
      employer: "",
      address: "",
      work_station: "",

      employer_detail: "",
      address_detail: "",
      work_station_detail: "",

      details: "",
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  componentDidMount() {
    this.getEmploymentDetails();
    console.log(parseInt(localStorage.getItem("stageid")));
  }

  getEmploymentDetails = async () => {
    let payload = {
      admno: parseInt(localStorage.getItem("id")),
    };
    const res = await getBankDetails(payload);
    // console.log(res.data);
    if (res.data.status === 200) {
      this.setState({
        details: res.data.data,
      });
    }
    // if (res.data.status === 200) {
    //   alert(res.data.stsMessage);
    //   helpers.resetForm();
    // } else {
    //   alert(res.data.stsMessage);
    // }
  };
  render() {
    // const { employee } = this.state;
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.stage < 12 ? (
              <>
                {" "}
                {/* <div className="page-content"> */}
                <div className="card">
                  <div className="card-header">
                    <div className="float-left card-header-title">
                      {/* Completed */}
                    </div>
                    <div className="float-right card-header-title">
                      Application Number : {localStorage.getItem("id")}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="block">
                      <div className="alert alert-warning">
                        {/* <Alert severity="warning"> */}
                        Please complete the previous stage by clicking "Next"
                        button
                        {/* {console.log(this.state.stage)} */}
                        {/* </Alert> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </>
            ) : (
              <>
                {this.state.details ? (
                  <EditBankDetails
                    id={this.state.details.id}
                    bankName={this.state.details.bankName}
                    accountNumber={this.state.details.accountNumber}
                    accountName={this.state.details.accountName}
                  />
                ) : (
                  <BankDetails />
                )}
              </>
            )}
          </Container>
          {/* {console.log(this.state.data[0].firstname)} */}
        </div>
      </React.Fragment>
    );
  }
}

export default Bank;
