import React from "react"
import Header from 'helpers/Header/Header'
import './landing.css'
import DetailTable from './detail-table'

const Home = () => {

  const ROWS = [
    { label: 'Registration Number', value: (localStorage.getItem("registrationNo")) },
    { label:'Admission Number', value: (localStorage.getItem("admission")) },
    { label:'Intake', value: (localStorage.getItem("intake")) },
    { label:'Registration Year', value: (localStorage.getItem("regYear")) },
    { label:'Year of Study', value: (localStorage.getItem("yearOfStudy")) },
    { label:'Username', value: (localStorage.getItem("userName")) },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card">
          <div className="card-header">
            <Header title="Welcome to Student Information Management System" />
          </div>
          <div className="card-body exam-card">
            <div className="block">
              <DetailTable rows={ROWS} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
