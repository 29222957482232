import React, { Component } from "react";
// import ReactLoading from "react-loading";
import params from "../../../util/api_routes/params";
import api from "../../../util/api";
// import { withRouter } from "react-router-dom";
import { Button, Alert, Modal } from "reactstrap";
import { FaRegSmile, FaUpload } from "react-icons/fa";
import "../../../assets/css/fieldset.css";
import { withRouter } from "react-router";
import changeStage from "../../../util/changeStage";

class PerformDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      declaration: "",
      yesIsOpen: false,
      noIsOpen: false,
    };
    this.onValueChange = this.onValueChange.bind(this);
    this.submitDeclaration = this.submitDeclaration.bind(this);
  }

  onValueChange = (e) => {
    this.setState({
      selectedOption: e.target.value,
    });
  };
  handleCheckBox = (e) => this.setState({ declaration: e.target.checked });

  redirectPage = async (e) => {
    let payLoad2 = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 16,
    };
    if (parseInt(localStorage.getItem("stageid")) === 15) {
      let result = await changeStage(payLoad2);
      if (result === 1) {
        this.setState({ loading: false, network_error: false });
        this.props.history.push("/registration-completed");
      } else {
      }
    } else {
      this.props.history.push("/registration-completed");
    }
  };

  submitDeclaration = async (e) => {
    e.preventDefault();
    if (this.state.declaration) {
      if (this.state.selectedOption) {
        //end point to submit declaration
        console.log(this.state.selectedOption);

        this.setState({ loading: true, network_error: false });
        let payLoad = {
          admno: parseInt(localStorage.getItem("id")),
          modeOfStudyCode: this.state.selectedOption,
        };
        try {
          await api
            .post(params.PERFORM_DECLARATION, payLoad)
            .then((res) => {
              if (res.status === 200) {
                if (res.data.stsCode === "0000") {
                  // console.log(res.data);
                  const status = this.redirectPage();
                } else {
                  this.setState({});
                }
              }
            })
            .catch((err) => {});
        } catch (error) {}

        // this.props.history.push("/registration-completed");
      } else {
        alert("Please choose whether full-time of evening");
      }
    } else {
      alert("Please check the declaration box");
    }
  };
  render() {
    return (
      <div>
        <div>
          <form onSubmit={this.submitDeclaration}>
            <fieldset className="scheduler-border">
              <legend className="scheduler-border">
                Check the box and choose whether Full-time or Evening
              </legend>
              <p>
                <input
                  style={{
                    cursor: "pointer",
                  }}
                  type="checkbox"
                  id="topping"
                  name="topping"
                  value="Paneer"
                  checked={this.state.declaration}
                  onChange={this.handleCheckBox}
                />{" "}
                I declare that all the information provided are mine and correct
                to the best of my knowledge. Any wrong information might lead to
                disqualification or displinary action taken against me.
              </p>
              <div className="row react-radio-group">
                <div className="radio col-sm-3">
                  <label>
                    <input
                      type="radio"
                      value="F"
                      name="full-time"
                      checked={this.state.selectedOption === "F"}
                      onChange={this.onValueChange}
                    />{" "}
                    <span style={{ fontWeight: "bold" }}>Full-time</span>
                  </label>
                </div>
                <div className="radio col-sm-3">
                  <label>
                    <input
                      type="radio"
                      value="E"
                      name="evening"
                      checked={this.state.selectedOption === "E"}
                      onChange={this.onValueChange}
                    />{" "}
                    <span style={{ fontWeight: "bold" }}>Evening</span>
                  </label>
                </div>
              </div>

              <Button
                variant="primary"
                type="submit"
                className="btn btn-round  btn-sm"
                // onClick={() => this.setState({ yesIsOpen: true })}
                // if (this.state.declaration)
              >
                <FaRegSmile />
                {"     "}Submit
              </Button>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
}
const PerformDeclaration2 = withRouter(PerformDeclaration);
export default PerformDeclaration2;
