import React, { Component } from "react";
class SecondarySchoolDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondary_school: [],
    };
  }
  render() {
    return (
      <div style={{ paddingTop: "5px" }}>
        {this.props.secondary_school.map((option, i) => (
          <div key={i}>
            {i === 0 ? (
              // <Accordion.Item eventKey="0" key={i}>
              <div>
                {option.center_name?.toUpperCase()} , {option.index_number} ,
                DIV:
                {option.results.division.division}, YEAR: {option.year}{" "}
                <table
                  className="table table-bordered table-striped"
                  key={i + "table"}
                >
                  <tbody key={i + "tablebody"}>
                    <tr key={i + "tableHeader"}>
                      <td key={0 + i}>CODE</td>
                      <td key={1 + i}>SUBJECT</td>
                      <td key={2 + i}>PASSMARK</td>
                    </tr>

                    {option.results.subjects.map((opt, j) => (
                      <tr key={j + i}>
                        <td key={j + i + 0}>{opt.subject_code}</td>
                        <td key={j + i + 1}>{opt.subject_name}</td>
                        <td key={j + i + 2}>{opt.grade}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                {option.center_name.toUpperCase()} , {option.index_number} ,
                DIV:
                {option.results.division.division}, YEAR: {option.year}
                <table
                  className="table table-bordered table-striped"
                  key={i + "table"}
                >
                  <tbody key={i + "tablebody"}>
                    <tr key={i + "tableHeader"}>
                      <td key={0 + i}>CODE</td>
                      <td key={1 + i}>SUBJECT</td>
                      <td key={2 + i}>PASSMARK</td>
                    </tr>

                    {option.results.subjects.map((opt, j) => (
                      <tr key={j + i}>
                        <td key={j + i + 0}>{opt.subject_code}</td>
                        <td key={j + i + 1}>{opt.subject_name}</td>
                        <td key={j + i + 2}>{opt.grade}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                
              </>
            )}
          </div>
        ))}
      </div>
    );
  }
}



export default SecondarySchoolDisplay;
