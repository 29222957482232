import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, Container } from "reactstrap";
import changeStage from "../../../util/changeStage";
import { FaArrowAltCircleRight, FaSave } from "react-icons/fa";

class SecondarySchoolDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondary_school: [],
    };
  }
  nextToDeclaration = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 14,
    };
    if (parseInt(localStorage.getItem("stageid")) === 13) {
      let result = await changeStage(payLoad);
      if (result === 1) {
        this.setState({ loading: false, network_error: false });
        this.props.history.push("/registration-nhif");
      } else {
      }
    } else {
      this.props.history.push("/registration-nhif");
    }
  };
  render() {
    return (
      <div style={{ paddingTop: "40px" }}>
        {this.props.secondary_school.map((option, i) => (
          <div key={i}>
            {i === 0 ? (
              // <Accordion.Item eventKey="0" key={i}>
              <div>
                {option.center_name
                  ? option.center_name.toUpperCase()
                  : option.center_name}{" "}
                , {option.index_number} , DIV:
                {option.results.division.division}, YEAR: {option.year}{" "}
                <table
                  className="table table-bordered table-striped"
                  key={i + "table"}
                >
                  <tbody key={i + "tablebody"}>
                    <tr key={i + "tableHeader"}>
                      <td key={0 + i}>CODE</td>
                      <td key={1 + i}>SUBJECT</td>
                      <td key={2 + i}>PASSMARK</td>
                    </tr>

                    {option.results.subjects.map((opt, j) => (
                      <tr key={j + i}>
                        <td key={j + i + 0}>{opt.subject_code}</td>
                        <td key={j + i + 1}>{opt.subject_name}</td>
                        <td key={j + i + 2}>{opt.grade}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  type="submit"
                  className="btn btn-round  btn-md"
                  variant="primary"
                  onClick={this.nextToDeclaration.bind(this)}
                >
                  Next <FaArrowAltCircleRight size={20} />
                </Button>
              </div>
            ) : (
              <>
                {option.center_name
                  ? option.center_name.toUpperCase()
                  : option.center_name}{" "}
                , {option.index_number} , DIV:
                {option.results.division.division}, YEAR: {option.year}
                <table
                  className="table table-bordered table-striped"
                  key={i + "table"}
                >
                  <tbody key={i + "tablebody"}>
                    <tr key={i + "tableHeader"}>
                      <td key={0 + i}>CODE</td>
                      <td key={1 + i}>SUBJECT</td>
                      <td key={2 + i}>PASSMARK</td>
                    </tr>

                    {option.results.subjects.map((opt, j) => (
                      <tr key={j + i}>
                        <td key={j + i + 0}>{opt.subject_code}</td>
                        <td key={j + i + 1}>{opt.subject_name}</td>
                        <td key={j + i + 2}>{opt.grade}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  type="submit"
                  className="btn btn-round  btn-md"
                  variant="primary"
                  onClick={this.nextToDeclaration.bind(this)}
                >
                  Next <FaArrowAltCircleRight size={20} />
                </Button>
              </>
            )}
          </div>
        ))}
      </div>
    );
  }
}

const SecondarySchoolDisplay2 = withRouter(SecondarySchoolDisplay);
export default SecondarySchoolDisplay2;
