import { HIDE_PRELOADER, SHOW_PRELOADER } from "../action-types";

export const showPreloader = () => {
  return (dispatch) => {
    dispatch({ type: SHOW_PRELOADER });
  };
};

export const hidePreloader = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_PRELOADER });
  };
};
