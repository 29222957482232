import Home from "../home";

const educationRoutes = [
  {
    path: "/education-background",
    exact: true,
    component: Home,
  },
];

export default educationRoutes;