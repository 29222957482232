import React, { useState, useEffect } from "react";
import { checkRegistrationStatus, studentRegistration } from "./services";
import Alert from "@mui/material/Alert";

const INITIAL_STATE = {
  academicYearId: null,
  academicYear: null,
  semester: null,
  yearOfStudy: null,
  isRegistered: null,
  isEligibleForReg: null,
  requiredFeePercentage: null,
  paidFeePercentage: null,
  lastSemExamRemark: null,
};

const SemesterRegistration = () => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    registrationStatus();
  }, []);

  const registrationStatus = async () => {
    const payload = {
      admno: localStorage.getItem("admission"),
    };

    const regStatus = await checkRegistrationStatus(payload);
    if (
      regStatus.data &&
      regStatus.data.data.isRegistered === 0 &&
      regStatus.data.data.isEligibleForReg === 1
    ) {
      setState((prevState) => ({
        ...prevState,
        academicYearId: regStatus.data.data.academicYearId,
        academicYear: regStatus.data.data.academicYear,
        semester: regStatus.data.data.semester,
        yearOfStudy: regStatus.data.data.yearOfStudy,
        isRegistered: regStatus.data.data.isRegistered,
        isEligibleForReg: regStatus.data.data.isEligibleForReg,
        requiredFeePercentage: regStatus.data.data.requiredFeePercentage,
        paidFeePercentage: regStatus.data.data.paidFeePercentage,
        lastSemExamRemark: regStatus.data.data.lastSemExamRemark,
        controlNumber: regStatus.data.data.controlNumber,
      }));
    } else if (
      regStatus.data &&
      regStatus.data.data.isRegistered === 0 &&
      regStatus.data.data.isEligibleForReg === 0
    ) {
      setState((prevState) => ({
        ...prevState,
        academicYearId: regStatus.data.data.academicYearId,
        academicYear: regStatus.data.data.academicYear,
        semester: regStatus.data.data.semester,
        yearOfStudy: regStatus.data.data.yearOfStudy,
        isRegistered: regStatus.data.data.isRegistered,
        isEligibleForReg: regStatus.data.data.isEligibleForReg,
        requiredFeePercentage: regStatus.data.data.requiredFeePercentage,
        paidFeePercentage: regStatus.data.data.paidFeePercentage,
        lastSemExamRemark: regStatus.data.data.lastSemExamRemark,
        controlNumber: regStatus.data.data.controlNumber,
      }));
    } else if (regStatus.data) {
      setState((prevState) => ({
        ...prevState,
        academicYearId: regStatus.data.data.academicYearId,
        academicYear: regStatus.data.data.academicYear,
        semester: regStatus.data.data.semester,
        isRegistered: regStatus.data.data.isRegistered,
      }));
    }
  };

  const studentSemesterRegistration = async () => {
    const payload = {
      admissionNo: localStorage.getItem("admission"),
      academicYearId: state.academicYearId,
      semesterId: state.semester,
      yearOfStudy: state.yearOfStudy,
    };
    const reg = await studentRegistration(payload);
    if (
      reg.data.status === 201 &&
      reg.data.message === "StudentRegistration Created Successfully"
    ) {
      window.location.reload();
    } else {
      alert(reg.data.message);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          {state.isRegistered === 1 ? (
            <>
              <Alert severity="success">
                YOU ARE ALREADY REGISTERED FOR THE CURRENT SEMESTER
              </Alert>
            </>
          ) : (
            <>
              {state.isRegistered === 0 && state.isEligibleForReg === 1 ? (
                <>
                  <ul>
                    <li>Please click link below to register</li>
                    <li>
                      <a
                        style={{ cursor: "pointer", color: "blue" }}
                        href="#"
                        onClick={() => studentSemesterRegistration()}
                      >
                        REGISTER
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  {state.isRegistered === 0 && state.isEligibleForReg === 0 ? (
                    <>
                      <Alert severity="warning">
                        You are not eligible for Registration,
                      </Alert>
                      <ul>
                        <li>
                          Required Fee Percentage for registration:
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {state.requiredFeePercentage}
                          </span>
                        </li>
                        <li>
                          Paid Fee Percentage for registration:
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {state.paidFeePercentage}
                          </span>
                        </li>
                        <li>
                          Last semester Examination Remark:
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {state.lastSemExamRemark}
                          </span>
                        </li>
                        <li>
                          Control number for Fee Payment:
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {state.controlNumber}
                          </span>
                        </li>
                        <li></li>
                      </ul>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SemesterRegistration;

