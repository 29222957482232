import React from "react";

import { toCurrency } from "helpers/utils";
import DetailTable from "./detail-table";

const ViewDuePaymentDetails = ({ dueDetails }) => {
  const DUE_ROWS = [
    { label: "Student Amount Due", value: toCurrency(dueDetails.dueDetail) },
    { label: "HESLB Amount Due", value: toCurrency(dueDetails.helsbDue) },
    { label: "Total Amount Due", value: toCurrency(dueDetails.amtDue) },
  ];

  return (
    <>
      <DetailTable rows={DUE_ROWS} />
    </>
  );
};
export default ViewDuePaymentDetails;
