import React, { Component } from "react";
import { Button, Container } from "reactstrap";
import { FaArrowAltCircleRight, FaSave } from "react-icons/fa";
import { withRouter } from "react-router";
import changeStage from "../../../util/changeStage";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";

class EditEmploymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employer: "",
      address: "",
      work_station: "",
      info_loading: null,
      stage: parseInt(localStorage.getItem("stageid")),
      network_error: false,
      fields: [],
      errors: [],
    };
  }
  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  // componentWillReceiveProps(props) {
  //   this.setState({
  //     employer: props.employer_detail,
  //     address: props.address_detail,
  //     work_station: props.work_station_detail,
  //   });
  //   console.log(this.state.employer);
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.employer !== prevState.employer &&
      nextProps.address !== prevState.address &&
      nextProps.work_station !== prevState.work_station
    ) {
      return {
        employer: nextProps.employer_detail,
        address: nextProps.address_detail,
        work_station: nextProps.work_station_detail,
      }; // <- this is setState equivalent
    }
    return null;
  }

  validateForm = () => {
    // let fields = this.state.fields;
    let fields = this.state;
    let errors = {};
    let formIsValid = true;
    if (!fields["employer"]) {
      formIsValid = false;
      errors["employer"] =
        "* Please fill in employer or Nil if you don't have any.";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] =
        "* Please fill in address or Nil if you don't have any.";
    }
    if (!fields["work_station"]) {
      formIsValid = false;
      errors["work_station"] =
        "* Please fill in work station or Nil if you don't have any.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  nextToOlevelDetails = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true, network_error: false });
      let payLoad = {
        applicationProfileId: parseInt(localStorage.getItem("id")),
        stageId: 13,
      };
      if (parseInt(localStorage.getItem("stageid")) === 12) {
        let result = await changeStage(payLoad);
        if (result === 1) {
          this.setState({ loading: false, network_error: false });
          this.props.history.push("/registration-bank");
        } else {
        }
      } else {
        this.props.history.push("/registration-bank");
      }
    }
  };

  SubmitEmploymentDetails = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let payLoad = {
        applicationProfileId: parseInt(localStorage.getItem("id")),
        employerName: this.state.employer,
        employerAddress: this.state.address,
        workStation: this.state.work_station,
      };
      await api
        .post(params.UPDATE_EMPLOYMENT_DETAILS, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                yesIsOpen: false,
                noIsOpen: false,
                loading: false,
                // redirect: true,
                network_error: false,
              });

              alert("successfully updated");
              window.location.reload();
              //oi
            } else {
              this.setState({
                network_error: true,
                loading: false,
              });
            }
          }
        })
        .catch((err) => {
          this.setState({ network_error: true, loading: false });
        });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <div className="float-left card-header-title">
              Edit Employment Details
            </div>
            <div className="float-right card-header-title">
              Application for : {localStorage.getItem("id")}
            </div>
          </div>
          <div className="card-block">
            <div className="block">
              {/* {this.state.stage === 1 ? ( */}
              <div className="row">
                <div className="col-sm-4">
                  {/* <p>
                    <b>Instructions</b>
                  </p>
                  <hr /> */}
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        {/* <ReactLoading type="cylon" color="#000" /> */}
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : null}

                  {this.state.error === true ? (
                    <>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                          <div className="alert alert-warning">
                            <strong>Warning!</strong> You are facing a
                            connection problem, please contact the system
                            administrator OR try later
                          </div>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {/* ) : ( */}
              <>
                {/* <div className="block login"> */}
                <span style={{ color: "green", fontWeight: "bold" }}>
                  If the informations are correct, click Next button, if not,
                  edit them then click Save button.
                </span>
                <hr />
                <form onSubmit={this.SubmitEmploymentDetails}>
                  {" "}
                  {/* <div className="block login"> */}
                  <div className="row">
                    <div className="form-group col-sm-3">
                      <label> Employer Name</label>
                      <font className="label-comment">*</font>
                      <input
                        type="text"
                        name="employer"
                        className="form-control input-placeholder"
                        value={this.state.employer}
                        onChange={this.handleInputState}
                        placeholder="employer"
                      ></input>
                      {this.state.errors.employer ? (
                        <div className="errorMsg">
                          {this.state.errors.employer}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-sm-3">
                      <label>
                        {" "}
                        Employer Adress
                        <font className="label-comment">*</font>
                      </label>
                      <input
                        type="text"
                        name="address"
                        className="form-control input-placeholder"
                        value={this.state.address}
                        onChange={this.handleInputState}
                        placeholder="address"
                      ></input>
                      {this.state.errors.address ? (
                        <div className="errorMsg">
                          {this.state.errors.address}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-sm-3">
                      <label> Work Station</label>
                      <font className="label-comment">*</font>
                      <input
                        type="text"
                        name="work_station"
                        className="form-control input-placeholder"
                        value={this.state.work_station}
                        onChange={this.handleInputState}
                        placeholder="work_station"
                      ></input>
                      {this.state.errors.work_station ? (
                        <div className="errorMsg">
                          {this.state.errors.work_station}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* </div> */}
                  <br />
                  <div className="row">
                    <div className="form-group col-sm-3">
                      <Button
                        type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        // onClick={}
                      >
                        Save <FaSave size={20} />
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        onClick={this.nextToOlevelDetails.bind(this)}
                      >
                        Next <FaArrowAltCircleRight size={20} />
                      </Button>
                    </div>
                  </div>
                </form>
                {/* </div> */}
              </>
              {/* )} */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const EditEmploymentDetails2 = withRouter(EditEmploymentDetails);
export default EditEmploymentDetails2;
