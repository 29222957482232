import Home from "../home";

const nhifRoutes = [
  {
    path: "/nhif-student",
    exact: true,
    component: Home,
  },
];

export default nhifRoutes;