import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { nhifRegistration } from "./services";

import BaseTextField from "helpers/TextField";
import Grid from "@material-ui/core/Grid";
import BaseDialog from "helpers/BaseDialog";
import ApplyNhifCard from "./apply-nhif-card";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "helpers/ConfirmDialog";

const FORM_INITIAL_STATE = {
  nhif_no: "",
  apply_card: false,
  openConfirmDialog: false,
  description: "",
};

const SubmitDetailsToNhif = () => {
  const [state, setState] = useState(FORM_INITIAL_STATE);

  const closeDialog = () => {
    return setState((prevState) => ({
      ...prevState,
      apply_card: false,
    }));
  };

  const openConfirmDialog = (item) => {
    setState((prevState) => ({
      ...prevState,
      openConfirmDialog: true,
    }));
  };

  const closeConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      openConfirmDialog: false,
    }));
  };

  const handleSearch = (helpers, values) => {
    let payLoad = {
      admno: localStorage.getItem("id"),
    };
    console.log(payLoad);
    // return
    //the following 4 lines should be commented later i.e apply_card:true
    // setState((prevState) => ({
    //   ...prevState,
    //   apply_card: true,
    // }));

    nhifRegistration(payLoad).then((res) => {
      console.log(res);
      if (res.data.statusCode === 200) {
        setState((prevState) => ({
          ...prevState,
          apply_card: true,
        }));
        // openConfirmDialog();
      } else {
        // return;
        setState((prevState) => ({
          ...prevState,
          description: res.data.message,
        }));
        openConfirmDialog();
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <h6>
            Click the button below to submit your details to NHIF for
            registration
          </h6>

          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(helpers, values) => handleSearch(values, helpers)}
          >
            {({ isValid, dirty }) => (
              <Form className="form-inline">
                <button
                  style={{ marginTop: "20px" }}
                  className="btn btn-round  btn-md btn-primary form-inline-button"
                  type="submit"
                  name="submit"
                >
                  SUBMIT DETAILS
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <BaseDialog
        fullWidth
        maxWidth={"md"}
        openDialog={state.apply_card}
        setOpenDialog={() => closeDialog()}
        title="APPLY NHIF CARD"
      >
        <ApplyNhifCard />
      </BaseDialog>

      <ConfirmDialog
        open={state.openConfirmDialog}
        title="Registration Status"
        text={state.description}
      >
        <Button autoFocus onClick={closeConfirmDialog} color="primary">
          Cancel
        </Button>
      </ConfirmDialog>
    </>
  );
};

export default SubmitDetailsToNhif;
