import React, { useState } from "react";

import InvoiceDetailTable from './invoice-detail-table'

//added
import BaseTable from "helpers/BaseTable";
import { getInvoiceDetails, searchByControlNumber } from "modules/fee-payment/services";
import useListQuery from "hooks/useListQuery";
import { toDate } from "helpers/utils";
import { Slot } from "helpers/Slots";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
// import EyeIcon from "mdi-react/EyeIcon";
import SettingsIcon from '@material-ui/icons/Settings';
import BaseDialog from "helpers/BaseDialog";
import ViewControlNumberDetails from "./view-control-number-details";

const DEBIT_DETAIL_HEADERS = [
  { value: "id", label: "S/N" },
  { value: (v) => toDate(v.createdDate), label: "CREATED DATE" },
  { value: "controlNo", label: "Control No" },
  { value: "description", label: "DESCRIPTION" },
  { value: "amount", label: "AMOUNT" },
  { value: "invoiceNo", label: "INVOICE" },
  // { value: (v) => (v.status ? v.status : "Default"), label: "STATUS" },
  { value: "currency", label: "CURRENCY" },
  { value: "actions", label: "REUSE CONTROL NO" },
];


const INITIAL_STATE = {
  controlNo: "",
  controlNumberDetails: [],
  isDetailDialogOpen: false,
  receipts: [],
};


const ViewDebitedDetails = ({ items }) => {
  const [state, setState] = useState(INITIAL_STATE);

  const q = useListQuery(getInvoiceDetails, {
    defaultParams: { admno: localStorage.getItem("admission") },
  });

  const closeDialog = () => {
    return setState((prevState) => ({
      ...prevState,
      isDetailDialogOpen: false,
    }));
  };

  const generateReceiptData = (response) => {
    return response.receipts.map((receipt, idx) => ({
      ...receipt,
      number: idx + 1,
      currency: "TZS",
    }));
  };
  const onInvoiceClick = async (controlNumber) => {
    const payload = {
      controlNo: controlNumber
    }
    const res = await searchByControlNumber(payload);
    if (res.data.stsCode === "0000") {
      setState((prevState) => ({
        ...prevState,
        isDetailDialogOpen: true,
        controlNumberDetails: res.data.data,
        receipts: generateReceiptData(res.data.data),
      }));
      // helpers.resetForm();
    } else {
      return;
    }
  };

  // const DEBIT_DETAIL_HEADERS = [
  //   { id: 1, name: 'number', label: 'S/N' },
  //   { id: 2, name: 'createdDate', label: 'CREATED DATE' },
  //   { id: 3, name: 'controlNo', label: 'CONTROL NO' },
  //   // {id: 3, name: 'payerId', label: 'REF. NO'},
  //   { id: 4, name: 'description', label: 'DESCRIPTION' },
  //   { id: 5, name: 'amount', label: 'AMOUNT' },
  //   { id: 6, name: 'invoiceNo', label: 'INVOICE' },
  //   { id: 7, name: 'cur', label: 'CURRENCY' },
  // ]


  return (
    <>
      {/* <InvoiceDetailTable headers={DEBIT_DETAIL_HEADERS} items={items} /> */}
      {/* {details && <DetailTable rows={DETAIL_ROWS} />} */}

      <BaseTable headers={DEBIT_DETAIL_HEADERS} items={q.data}>
        {(row) => {
          return (
            <Slot name="actions">
              <Tooltip title="View Invoice" placement="top" arrow>
                <IconButton onClick={(e) => onInvoiceClick(row.controlNo)}>
                  <SettingsIcon color="blue" />
                </IconButton>
              </Tooltip>
            </Slot>
          );
        }}
      </BaseTable>

      {state.controlNumberDetails !== null ? (
        <div>
          <BaseDialog
            fullWidth
            maxWidth={"md"}
            openDialog={state.isDetailDialogOpen}
            setOpenDialog={() => closeDialog()}
            title="CONTROL NUMBER DETAILS"
          >
            <ViewControlNumberDetails
              onClose={() => closeDialog()}
              details={state.controlNumberDetails}
              receipts={state.receipts}
            />
          </BaseDialog>
        </div>
      ) : null}
    </>
  )
}

export default ViewDebitedDetails
