import * as React from "react";
import MUIDialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: { minWidth: "650px", width: "auto" },
  divider: { padding: 0 },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "& div": {
      color: "white",
    },
  },
  dialogWrapper: {
    padding: theme.spacing(0),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  icon: {
    cursor: "pointer",
  },
}));

const Dialog = (props) => {
  const { title, children, openDialog, setOpenDialog, ...otherProps } = props;
  const classes = useStyles();

  const dialogConfig = {
    ...otherProps,
  };

  return (
    <div>
      <MUIDialog {...dialogConfig}
      open={openDialog}
      >
        <DialogTitle className={classes.root}>
          <Grid container>
            <Grid item>
              <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                {title.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item xs></Grid>
            {setOpenDialog && (
              <Grid item>
                <CloseIcon
                  className={classes.icon}
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent dividers style={{ minHeight: "130px", height: "auto" }}>
          {children}
        </DialogContent>
      </MUIDialog>
    </div>
  );
};

export default Dialog;
