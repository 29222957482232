import Home from "../home";

const registrationRoutes = [
  {
    path: "/registration",
    exact: true,
    component: Home,
  },
];

export default registrationRoutes;