import React, { Component } from "react";
import params from "../../util/api_routes/params";
import api from "../../util/api";
import SecondarySchoolDisplay from "./secondarySchoolDisplay";


class PrimaryOlevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary_school: [],
      school: "",
      fields: {},
      errors: {},
      networkError: "",
      apiError: "",
      addingError: "",
      addingnetworkError: "",
      internal_track: null,
      external_track: null,
      school_data: [],
      info_loading: null,
      network_error: false,
      school_data_alevel: [],
      info_loading: null,
      network_error: false,
      filling_arrow: null,
    };
  }
  componentDidMount() {
    this.getPrimaryEducation();
    this.getSecondarySchools();
  }
  getPrimaryEducation = async () => {
    await api
      .get(
        params.PRIMARY_EDUCATION + "/" + parseInt(localStorage.getItem("admission"))
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              primary_school: res.data.data,
              application_id: res.data.data.profileId,
              school: res.data.data.schoolName,
              year: Number(res.data.data.yearCompleted),
              networkError: false,
              addingError: false,
              apiError: false,
            });
          }
          if (res.data.stsCode === "0003") {
            this.setState({
              primary_school: [],
              networkError: false,
              apiError: false,
            });
          }
        } else {
          this.setState({ networkError: true });
        }
      })
      .catch((err) => {
        this.setState({ apiError: true });
      });
  };

  getSecondarySchools = async () => {
    this.setState({ info_loading: true });
    await api
      .get(params.OLEVEL_LIST + parseInt(localStorage.getItem("admission")))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              school_data: res.data.data,
              info_loading: false,
              network_error: false,
            });
            // console.log(this.state.school_data);
          }
        } else {
          this.setState({
            school_data: [],
            info_loading: false,
            network_error: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ info_loading: false, network_error: true });
      });
  };
 
  render() {
    return (
    <>
       <div >      
           <div >
           <> 
           <h6>Primary Education</h6>
                <table className="table table-bordered">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#f1f5f7",
                          width: "20%",
                          fontWeight: "bold",
                          fontFamily: "Helvetica",
                        }}
                      >
                        School Name
                      </th>
                      <td style={{ fontStyle: "italic" }}>
                        {this.state.school}
                      </td>
                    </tr>
                    <tr>
                      <th
                        style={{
                          backgroundColor: "#f1f5f7",
                          width: "20%",
                          fontWeight: "bold",
                          fontFamily: "Helvetica",
                        }}
                      >
                        Completion Year
                      </th>
                      <td style={{ fontStyle: "italic" }}>
                        {this.state.year}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
           </div>
           <div>
           <h6>O-level Secondary Education</h6>
           <SecondarySchoolDisplay
            secondary_school={
              this.state.school_data
            }
          />
          </div>
       </div> 
    </>
    );
  }
}

export default PrimaryOlevel;