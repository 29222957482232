import React from "react"

const DetailTable = ({ rows, children }) => {
  const tdStyle = {
    fontStyle: 'italic'
  }

  const spanStyle = {
    fontStyle: 'bold'
  }

  const thStyle = {
    backgroundColor: "#f1f5f7",
    width: "20%",
    fontWeight: "bold",
    fontFamily: "Helvetica",
  };

  return (
    <>
      <table className="table table-bordered">
        <thead></thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              <th style={thStyle}>{row.label}</th>
              <td style={tdStyle}>
                <span style={spanStyle}>{row.value}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {children}
      </>
  )
}

export default DetailTable
