export default function PageContent({ children, title, actions, ...props }) {
  return (
    <div className="page-content" {...props}>
      <div className="card">
        <div className="card-header">
          <div>
            <div className="float-left card-header-title">{title}</div>
            <div className="float-right card-header-title">
              {actions}
            </div>
          </div>
        </div>
        <div className="card-body exam-card">
          <div className="block">{children}</div>
        </div>
      </div>
    </div>
  );
}
