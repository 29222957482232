
 const clearance_colors = {
  ACTION_REQUIRED: "red",
  REJECTED: "red",
  PENDING: "red",
  APPROVED: "green",
  COMPLETED: "green",
  FAILED: "red",
};

export default clearance_colors;
