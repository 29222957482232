import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//My imports student registration
import FeePayment from "../pages/StudentRegistration/FeePayment/FeePayment";
import Employment from "../pages/StudentRegistration/EmploymentDetails/index";
import Bank from "../pages/StudentRegistration/BankDetails/index";
import Personal from "../pages/StudentRegistration/PersonalDetails/index";
import Declaration from "../pages/StudentRegistration/Declaration/index";
import Education from "../pages/StudentRegistration/Education/index";
import ResponseForm from "../pages/StudentRegistration/Nhif/home";
import AfterDeclation from "../pages/StudentRegistration/AfterDeclaration/index";

//STUDENT ROUTES
import { landingRoutes } from "modules/landing";
import { profileRoutes } from "modules/profile";
import { educationRoutes } from "modules/education-background";
import { nhifRoutes } from "modules/nhif";
import { feePaymentRoutes } from "modules/fee-payment";
import { programRoutes } from "modules/program-modules";
import { registrationRoutes } from "modules/registration";
import { examinationRoutes } from "modules/examination-results";
import hostelRoutes from "modules/hostel/routes";
import clearanceRoutes from "modules/clearance/routes";

//student after registration
import WelcomePage from "../pages/welcome/index";

const [landRoutes] = landingRoutes;
const [profRoutes] = profileRoutes;
const [eduRoutes] = educationRoutes;
const [nhRoutes] = nhifRoutes;
const [feeRoutes] = feePaymentRoutes;
const [progRoutes] = programRoutes;
const [regRoutes] = registrationRoutes;
const [examRoutes] = examinationRoutes;

const authProtectedRoutes = [
  { path: "/registration-feepayment", component: FeePayment },
  { path: "/registration-personal", component: Personal },
  { path: "/registration-employment", component: Employment },
  { path: "/registration-bank", component: Bank },
  { path: "/registration-education", component: Education },
  { path: "/registration-nhif", component: ResponseForm },
  { path: "/registration-declaration", component: Declaration },
  { path: "/registration-completed", component: AfterDeclation },
  { path: "/dashboard", component: Dashboard },

  landRoutes,
  profRoutes,
  eduRoutes,
  nhRoutes,
  feeRoutes,
  progRoutes,
  regRoutes,
  examRoutes,
  ...hostelRoutes,
  ...clearanceRoutes,
  //routes after registration
  { path: "/start", component: WelcomePage },
  { path: "/", exact: true, component: () => <Redirect to="/landing" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth-lock-screen", component: AuthLockScreen },

  // student registration routes
];

export { authProtectedRoutes, publicRoutes };
