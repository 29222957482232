import React from "react";
import { MDBDataTable } from "mdbreact";
import "helpers/Tables/styles.css";

const DetailTable = ({ rows, children, ...otherProps }) => {
  const tdStyle = {
    fontStyle: "normal",
  };

  const spanStyle = {
    fontStyle: "bold",
  };

  const thStyle = {
    backgroundColor: "rgb(248 249 250)",
    borderBottom: "1px solid #ccc",
    width: "30%",
    fontWeight: "bold",
  };

  const thConfig = {
    ...otherProps,
  };

  return (
    <>
      <table className="table table-bordered">
        <thead></thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              <th {...thConfig} style={thStyle}>{row.label}</th>
              <td style={tdStyle}>
                <span style={spanStyle}>{row.value}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {children}
    </>
  );
};

const DataTable = ({ rows, columns, ...otherProps }) => {
  const data = {
    rows,
    columns,
  };

  const tableConfig = {
    searching: true,
    striped: true,
    paging: true,
    ...otherProps,
  };

  return (
    <MDBDataTable {...tableConfig} responsive bordered striped data={data} />
  );
};

export { DataTable, DetailTable };
