import React, { useEffect, useState } from "react"
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider'
import { Link } from "react-router-dom";

import { Route } from 'react-router-dom'

import Header from 'helpers/Header/Header'

// import PersonalDetails from './personal-details'
// import AccountDetails from './account-details'

import './program.css'

const Home = () => {
  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card">
          <div className="card-header">
            <Header title=" Program Module" />
          </div>
          <div className="card-body exam-card">
            <div className="block">
             program modules
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
