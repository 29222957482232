import { HIDE_PRELOADER, SHOW_PRELOADER } from "../action-types";

const initialState = {
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_PRELOADER:
      return { ...state, loading: true };
    case HIDE_PRELOADER:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
