import axios from "api/axios";

export const APIS = {
  CLEARANCE_API: "/api/clearances/me",
};

const getClearance = async (params) => {
  return await axios.get(`${APIS.CLEARANCE_API}/0`, { params });
};

const createClearance = async (payload) => {
  return await axios.post(`${APIS.CLEARANCE_API}/create`, payload);
};
const getStudentClearanceResults = async ({ id, ...params }) => {
  return await axios.get(`${APIS.CLEARANCE_API}/0/results`, { params });
};

export { getClearance, createClearance, getStudentClearanceResults };
