import axios from "api/axios";

const API = {
  CAMPUS_LIST_API: "/api/campus/list",
  INTAKE_LIST_API: "/api/intake/all",
  PROGRAM_LIST_API: "/api/program/all",
  ACADEMIC_YEARS_API: "/api/academic_year/all",
  SEMESTER_LIST_API: "/api/semester/all",
  MODULE_BY_DEPARTMENT_API: "/api/module/by_department",
  FETCH_RESULTS_API: "/api/staff/exams/results/fetch",
  SEARCH_STUDENT_WITH_RESULTS_API: "/api/examination/semester/fetch",
  SEARCH_STUDENTS_API: "/api/student/search",
  PROCESS_RESULTS: "/api/examination/process/result",
  CREATE_UPDATE_RESULTS: "/api/examination/cat/sup/semester/create-update",
  EXAM_UPLOAD_RESULTS_API: "/api/examination/semester/upload",
  GET_RESULTS_API: "/api/examination/semester/get-exams",
  GRADING_RESULTS_API: "/api/examination/grading-system/fetch",
  GRADING_CREATE_UPDATE: "/api/examination/grading-system/create-update",
  STUDENT_RESULTS_SUMMARY_API: "/api/student/result",
  OVERALL_RESULTS_API: "/api/student/overall/summary/result",
  PUBLISH_RESULT_API: "/api/examination/status/publish",
  STUDENT_REGISTRATION_RESULTS_API: "/api/student_registration/group_by_yos",
  // YEAR_SEMESTER_RESULTS:/api/examination/semester/result?academicYear=2021/2021&moduleCode=ACU07105&regNo=DSM/BBA/22/00001&semesterId=1&year=1"
  YEAR_SEMESTER_RESULTS: "/api/examination/semester/result",
  STUDENT_MODULE_RESULTS_API: "/api/student/module/result",
  STUDENT_REMARKS: "/api/examination/semester/remarks",
};

const getStudentResultSummary = async (params) => {
  return await axios.get(`${API.STUDENT_RESULTS_SUMMARY_API}`, { params });
};

const getOverallResults = async (params) => {
  return await axios.get(`${API.OVERALL_RESULTS_API}`, { params });
};

const uploadResults = async (payload) => {
  await axios.post(API.EXAM_UPLOAD_RESULTS_API, payload);
};

const createUpdateResults = async (payload) => {
  return await axios.post(API.CREATE_UPDATE_RESULTS, payload);
};

const getResults = async () => {
  return await axios.get(API.GET_RESULTS_API);
};

const fetchResults = async (payload) => {
  await axios.get(`${API.FETCH_RESULTS_API}`, payload);
};

const processResults = async (payload) => {
  return await axios.post(`${API.PROCESS_RESULTS}`, payload);
};

const searchStudent = async (params) => {
  return await axios.get(`${API.SEARCH_STUDENT_WITH_RESULTS_API}`, { params });
};

const getGrading = async (payload) => {
  return await axios.get(`${API.GRADING_RESULTS_API}`, payload);
};

const createUpdateGrading = async (payload) => {
  return await axios.post(`${API.GRADING_CREATE_UPDATE}`, payload);
};

const publishResults = async (payload) => {
  return await axios.post(`${API.PUBLISH_RESULT_API}`, payload);
};

const searchStudents = async (params) => {
  return await axios.get(`${API.SEARCH_STUDENTS_API}`, { params });
};

const getStudentRegistraionResults = async (params) => {
  return await axios.get(`${API.STUDENT_REGISTRATION_RESULTS_API}`, { params });
};

const getAcademicYearSemesterResults = async (params) => {
  return await axios.get(`${API.YEAR_SEMESTER_RESULTS}`, { params });
};

const getStudentModuleResults = async (params) => {
  return await axios.get(`${API.STUDENT_MODULE_RESULTS_API}`, { params });
};

const getStudentRemarks = async (params) => {
  return await axios.get(`${API.STUDENT_REMARKS}`, { params });
};

export {
  API,
  createUpdateGrading,
  createUpdateResults,
  fetchResults,
  getAcademicYearSemesterResults,
  getGrading,
  getOverallResults,
  getResults,
  getStudentModuleResults,
  getStudentRegistraionResults,
  getStudentRemarks,
  getStudentResultSummary,
  processResults,
  publishResults,
  searchStudent,
  searchStudents,
  uploadResults,
};
