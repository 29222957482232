import React, { Component } from "react";
import { Button, Container } from "reactstrap";
import { FaArrowAltCircleRight } from "react-icons/fa";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";

class EmploymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employer: "",
      address: "",
      work_station: "",
      stage: parseInt(localStorage.getItem("stageid")),
      network_error: false,
      fields: [],
      errors: [],
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };
  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["employer"]) {
      formIsValid = false;
      errors["employer"] =
        "* Please fill in employer or Nil if you don't have any.";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] =
        "* Please fill in address or Nil if you don't have any.";
    }
    if (!fields["work_station"]) {
      formIsValid = false;
      errors["work_station"] =
        "* Please fill in work station or Nil if you don't have any.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };
  submitEmploymentDetails = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let payLoad = {
        applicationProfileId: parseInt(localStorage.getItem("id")),
        employerName: this.state.fields.employer,
        employerAddress: this.state.fields.address,
        workStation: this.state.fields.work_station,
      };
      try {
        await api
          .post(params.ADD_EMPLOYMENT_DETAILS, payLoad)
          .then((res) => {
            if (res.status === 200) {
              if (res.data.stsCode === "0000") {
                // this.setState({
                //   employer: "",
                //   address: "",
                //   work_station: "",
                // });
                console.log(res.data.stsCode);
                alert("successfully added");
                window.location.reload(); //to check if the data exists to redirect to EditEmployment Details
              } else {
                this.setState({
                  employerName: " ",
                  employerAddress: " ",
                  workStation: "",
                });
                this.setState({ credentialError: res.data.stsMessage });
              }
            }
          })
          .catch((err) => {});
      } catch (error) {}
    }
    //Endpoint
    //then window reload
  };
  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header">
            <div className="float-left card-header-title">
              Employment Details
            </div>
            <div className="float-right card-header-title">
              Application for : {localStorage.getItem("id")}
            </div>
          </div>
          <div className="card-block">
            <div className="block">
              {/* {this.state.stage === 1 ? ( */}
              <div className="row">
                <div className="col-sm-4">
                  {/* <p>
                    <b>Instructions</b>
                  </p>
                  <hr /> */}
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        {/* <ReactLoading type="cylon" color="#000" /> */}
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : null}

                  {this.state.error === true ? (
                    <>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                          <div className="alert alert-warning">
                            <strong>Warning!</strong> You are facing a
                            connection problem, please contact the system
                            administrator OR try later
                          </div>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {/* ) : ( */}
              <>
                {/* <div className="block login"> */}
                <div>
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Fill 'Nil' in all the boxes if you don't have any employment
                    detail.
                  </span>
                </div>
                <hr />
                <form onSubmit={this.submitEmploymentDetails}>
                  {" "}
                  {/* <div className="block login"> */}
                  <div className="row">
                    <div className="form-group col-sm-3">
                      <label> Employer Name (If Any)</label>
                      <font className="label-comment">*</font>
                      <input
                        type="text"
                        name="employer"
                        className="form-control input-placeholder"
                        value={this.state.employer}
                        onChange={this.handleInputState}
                        placeholder="employer"
                      ></input>
                      {this.state.errors.employer ? (
                        <div className="errorMsg">
                          {this.state.errors.employer}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-sm-3">
                      <label>
                        {" "}
                        Employer Adress
                        <font className="label-comment">*</font>
                      </label>
                      <input
                        type="text"
                        name="address"
                        className="form-control input-placeholder"
                        value={this.state.first_name}
                        onChange={this.handleInputState}
                        placeholder="address"
                      ></input>

                      {this.state.errors.address ? (
                        <div className="errorMsg">
                          {this.state.errors.address}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-sm-3">
                      <label> Work Station</label>
                      <font className="label-comment">*</font>
                      <input
                        type="text"
                        name="work_station"
                        className="form-control input-placeholder"
                        value={this.state.work_station}
                        onChange={this.handleInputState}
                        placeholder="work_station"
                      ></input>
                      {this.state.errors.work_station ? (
                        <div className="errorMsg">
                          {this.state.errors.work_station}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* </div> */}
                  <br />
                  <div className="row">
                    <div className="form-group col-sm-3">
                      <Button
                        type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        // onClick={}
                      >
                        Save <FaArrowAltCircleRight size={20} />
                      </Button>
                    </div>
                  </div>
                </form>
                {/* </div> */}
              </>
              {/* )} */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default EmploymentDetails;
