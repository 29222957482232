import { Grid, Box, Paper, Typography } from "@material-ui/core";
import TextBox from "components/TextBox";
import InfoIcon from "@material-ui/icons/InfoTwoTone";
import useUnitQuery from "hooks/useUnitQuery";
import { getStudentClearanceResults } from "../service";
import clearance_colors from "./clearance-colors";

export default function ClearanceOutputs() {
  const query_results = useUnitQuery(getStudentClearanceResults);
  if (!query_results.ok) return null;
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        marginTop: 30,
      }}
    >
      {query_results.data.map((result, index) => (
        <ClearanceOutput index={index} result={result} />
      ))}
    </Box>
  );
}

function ClearanceOutput({ result, index }) {
  const head = result.resultStaff;
  const department = result.department;
  const statusColor = clearance_colors[result.status];
  return (
    <Paper
      style={{
        padding: 20,
        borderLeft: "5px solid #46465f",
        borderRight: `5px solid ${statusColor}`,
      }}
    >
      <Grid item container spacing={2}>
        <Grid item md={12}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Typography style={{ fontWeight: "bold", flex: 1 }}>
              {index + 1}. {department.name}
            </Typography>
            <Typography style={{ fontWeight: "bold", color: statusColor }}>
              {result.status ?? "PENDING"}
            </Typography>
            <InfoIcon style={{ color: statusColor }} />
          </Box>
        </Grid>
        <Grid item md={1}>
          <TextBox label="Designation" value={head?.designation} />
        </Grid>
        <Grid item md={4}>
          <TextBox
            label="Name"
            value={head ? `${head.firstname} ${head.middlename} ${head.lastName}` : ''}
          />
        </Grid>
        <Grid item md={12}>
          <TextBox multiline label="Remarks" value={result.remarks} />
        </Grid>
      </Grid>
    </Paper>
  );
}
