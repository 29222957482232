import { DetailTable } from "helpers/Tables";
import PageContent from "components/PageContent";
import useUnitQuery from "hooks/useUnitQuery";
import { getAllocation } from "./service";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import Button from "helpers/BaseButton";

const makeDetails = (ab) => [
  {
    label: "Building",
    value: ab.room?.building?.name,
  },
  {
    label: "Room",
    value: ab.room?.name,
  },
  {
    label: "Start Date",
    value: ab.start_date,
  },
  {
    label: "End Date",
    value: ab.end_date,
  },
  {
    label: "Allocation Date",
    value: ab.allocation_date,
  },
  {
    label: "Approver",
    value: !ab.approved_by
      ? "- -"
      : `${ab.approved_by?.firstName} ${ab.approved_by?.lastName}`,
  },
  {
    label: "Status",
    value: ab.status,
  },
];

export default function ViewHostelAllocation() {
  const params = useParams();
  const query = useUnitQuery(
    (params) => getAllocation(params),
    {
      prefetch: false,
      params,
    },
    ["id"],
  );
  const details = useMemo(() => {
    const ap = query.data;
    if (!ap) return [];
    return makeDetails(ap);
  }, [query.data]);
  const data = query.data;
  return (
    <>
      <PageContent title="Absence">
        <DetailTable width={30} rows={details} />
      </PageContent>
    </>
  );
}
