import Home from "../home";

const landingRoutes = [
  {
    path: "/landing",
    exact: true,
    component: Home,
  },
];

export default landingRoutes;