import React, { Component, useState } from "react";
import { Button, Row, Container } from "reactstrap";
import { FaArrowAltCircleRight, FaSave } from "react-icons/fa";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import tzPhoneNumberValidator from "../../../util/tzPhoneNumberValidator";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,

      first_name: "",
      last_name: "",
      middle_name: "",
      sex: "",
      dob: "",
      mstatus: "",
      // -----------//
      region: "",
      district: "",
      cellPhone: "",
      phone: "",
      // -----------//
      email: "",
      address: "",
      // -----------//
      fullname: "",
      relationship: "",
      region2: "",
      district2: "",
      phone2: "",
      address2: "",
      // -----------//
      selectedImage: null,
      fields: [],
      errors: [],

      info_loading: null,

      stage: parseInt(localStorage.getItem("stageid")),
      network_error: false,
    };
  }

  imageSelectedHandler = (e) => {
    this.setState({ selectedImage: e.target.file[0] });
  };

  imageUploadHandler = () => {};

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["title"]) {
      formIsValid = false;
      errors["title"] = "* Please select title.";
    }
    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "* Please fill in first name.";
    }
    if (!fields["middle_name"]) {
      formIsValid = false;
      errors["middle_name"] = "* Please fill in middle name.";
    }
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "* Please fill in last name.";
    }
    if (!fields["dob"]) {
      formIsValid = false;
      errors["dob"] = "* Please fill in date of birth.";
    }
    if (!fields["sex"]) {
      formIsValid = false;
      errors["sex"] = "* Please fill in gender.";
    }
    if (!fields["mstatus"]) {
      formIsValid = false;
      errors["mstatus"] = "* Please select marital status.";
    }
    if (!fields["address"]) {
      formIsValid = false;
      errors["address"] = "* Please fill in address.";
    }
    if (!fields["district"]) {
      formIsValid = false;
      errors["district"] = "* Please fill in district.";
    }
    if (!fields["region"]) {
      formIsValid = false;
      errors["region"] = "* Please fill in region.";
    }
    if (!fields["phone"] || typeof fields["phone"] === undefined) {
      formIsValid = false;
      errors["phone"] = "* Please fill the phone number.";
    } else if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["phone2"] || typeof fields["phone2"] === undefined) {
      formIsValid = false;
      errors["phone2"] = "* Please fill the phone number.";
    } else if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone2"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "* Please fill in email.";
    }

    // ----------//
    if (!fields["address2"]) {
      formIsValid = false;
      errors["address2"] = "* Please fill in address.";
    }
    if (!fields["district2"]) {
      formIsValid = false;
      errors["district2"] = "* Please fill in district.";
    }
    if (!fields["region2"]) {
      formIsValid = false;
      errors["region2"] = "* Please fill in region.";
    }
    if (!fields["phone"] || typeof fields["phone"] === undefined) {
      formIsValid = false;
      errors["phone"] = "* Please fill the phone number.";
    } else if (tzPhoneNumberValidator(fields["phone"]) === false) {
      formIsValid = false;
      errors["phone"] = "* Phone number is wrong,please check and re-type.";
    }
    if (!fields["address2"]) {
      formIsValid = false;
      errors["address2"] = "* Please fill in address.";
    }
    if (!fields["relationship"]) {
      formIsValid = false;
      errors["relationship"] = "* Please fill in relationship.";
    }
    if (!fields["fullname"]) {
      formIsValid = false;
      errors["fullname"] = "* Please fill in fullname.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  SubmitPersonalDetails = async (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      let payLoad = {
        applicationProfileId: parseInt(localStorage.getItem("id")),
        title: this.state.title,
        fisrt_name: this.state.first_name,
        middle_name: this.state.middle_name,
        last_name: this.state.last_name,
        dob: this.state.dob,
        sex: this.state.sex,
        mstatus: this.state.mstatus,
        postalBox: this.state.address,
        district: this.state.district,
        region: this.state.region,
        cellPhone: this.state.phone,
        email: this.state.email,
        // -------------------//
        fullname: this.state.fullname,
        relationship: this.state.relative,
        cellPhone2: this.state.phone2,
        region2: this.state.region2,
        district2: this.state.district2,
        postalBox2: this.state.address2,
      };
      // let payLoad_2 = {
      //   applicationProfileId: parseInt(localStorage.getItem("id")),
      // };
      // let result = await changeStage(payLoad_2);
      await api
        .post(params.ADD_PERSONAL_DETAILS, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                yesIsOpen: false,
                noIsOpen: false,
                loading: false,
                redirect: true,
                full_name: "",
                network_error: false,
              });
              // this.props.history.push("/personal/nextofkin");
              window.location.reload();
            } else {
              this.setState({
                network_error: true,
                loading: false,
              });
            }
          }
        })
        .catch((err) => {
          this.setState({ network_error: true, loading: false });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <Container fluid>
          <div className="page-content"> */}
        <div className="card">
          <div className="card-header">
            <div className="float-left card-header-title">Personal Details</div>
            <div className="float-right card-header-title">
              Application for : {localStorage.getItem("id")}
            </div>
          </div>
          <div className="card-block">
            <div className="block">
              {/* {this.state.stage === 1 ? ( */}
              <div className="row">
                <div className="col-sm-4">
                  <p>
                    <b>Instructions</b>
                  </p>
                  <hr />
                  {this.state.info_loading === true ? (
                    <div className="row">
                      <div className="col-sm-6"></div>
                      <div className="col-sm-2">
                        {/* <ReactLoading type="cylon" color="#000" /> */}
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  ) : null}

                  {this.state.error === true ? (
                    <>
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-10">
                          <div className="alert alert-warning">
                            <strong>Warning!</strong> You are facing a
                            connection problem, please contact the system
                            administrator OR try later
                          </div>
                        </div>
                        <div className="col-sm-1"></div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {/* ) : ( */}
              <>
                {/* <div className="block login"> */}
                <span style={{ color: "green", fontWeight: "bold" }}>
                  Your Own Personal Details
                </span>
                <form onSubmit={this.SubmitPersonalDetails}>
                  {" "}
                  <div className="block login">
                    <div className="row">
                      <div className="form-group col-sm-3">
                        <label> Title</label>
                        <font className="label-comment">*</font>
                        <select
                          name="title"
                          className="selection-box  input-placeholder"
                          value={this.state.title}
                          onChange={this.handleInputState}
                        >
                          <option key={0} value="">
                            {" "}
                            -- Select the title --{" "}
                          </option>
                          <option key={1} value="Mr">
                            Mr
                          </option>
                          <option value="Ms">Miss</option>
                          <option value="Eng">Engineer</option>
                          <option value="Dr">Doctor</option>
                          <option value="Prof">Proffesor</option>
                        </select>
                        {this.state.errors.title ? (
                          <div className="errorMsg">
                            {this.state.errors.title}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label>
                          {" "}
                          First name
                          <font className="label-comment">*</font>
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control input-placeholder"
                          value={this.state.first_name}
                          onChange={this.handleInputState}
                          placeholder="first name"
                        ></input>

                        {this.state.errors.first_name ? (
                          <div className="errorMsg">
                            {this.state.errors.first_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> Middle name</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="middle_name"
                          className="form-control input-placeholder"
                          value={this.state.middle_name}
                          onChange={this.handleInputState}
                          placeholder="middle name"
                        ></input>
                        {this.state.errors.middle_name ? (
                          <div className="errorMsg">
                            {this.state.errors.middle_name}
                          </div>
                        ) : null}
                      </div>

                      {/* ---------image------ */}
                      <div className="col-sm-3">
                        Image <span className="font-css top">*</span>
                        <div className="">
                          <input
                            type="file"
                            id="file-input"
                            name="image"
                            onChange={this.imageSelectedHandler}
                          />
                        </div>
                        <button onClick={this.imageUploadHandler}>
                          Upload
                        </button>
                      </div>
                      {/* ---------image------ */}
                    </div>
                    <hr />
                    <div className="row">
                      <div className="form-group col-sm-3">
                        <label> Last name</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="last_name"
                          className="form-control input-placeholder"
                          value={this.state.last_name}
                          onChange={this.handleInputState}
                          placeholder="last name"
                        ></input>
                        {this.state.errors.last_name ? (
                          <div className="errorMsg">
                            {this.state.errors.last_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> Date of birth</label>
                        <font className="label-comment">*</font>
                        <input
                          type="date"
                          name="dob"
                          className="form-control input-placeholder"
                          value={this.state.dob}
                          onChange={this.handleInputState}
                        ></input>
                        {this.state.errors.dob ? (
                          <div className="errorMsg">
                            {this.state.errors.dob}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> Gender</label>
                        <font className="label-comment">*</font>
                        <select
                          name="sex"
                          className="selection-box  input-placeholder"
                          value={this.state.sex}
                          onChange={this.handleInputState}
                        >
                          <option key={0} value="">
                            {" "}
                            -- Select gender --{" "}
                          </option>
                          <option key={1} value="M">
                            Male
                          </option>
                          <option key={2} value="F">
                            Female
                          </option>
                        </select>
                        {this.state.errors.sex ? (
                          <div className="errorMsg">
                            {this.state.errors.sex}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <hr />
                    {/* ----phase 0----- */}
                    <div className="row">
                      <div className="form-group col-sm-3">
                        <label> Marital Status</label>
                        <font className="label-comment">*</font>
                        <select
                          name="mstatus"
                          className="selection-box  input-placeholder"
                          value={this.state.mstatus}
                          onChange={this.handleInputState}
                        >
                          <option key={0} value="">
                            {" "}
                            -- Marital status --{" "}
                          </option>
                          <option key={1} value="Married">
                            Single
                          </option>
                          <option key={2} value="Single">
                            Married
                          </option>
                        </select>
                        {this.state.errors.mstatus ? (
                          <div className="errorMsg">
                            {this.state.errors.mstatus}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> P.O Box</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="address"
                          className="form-control input-placeholder"
                          value={this.state.address}
                          onChange={this.handleInputState}
                          placeholder="box"
                        ></input>
                        {this.state.errors.address ? (
                          <div className="errorMsg">
                            {this.state.errors.address}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> District</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="district"
                          className="form-control input-placeholder"
                          value={this.state.district}
                          onChange={this.handleInputState}
                          placeholder="district"
                        ></input>
                        {this.state.errors.district ? (
                          <div className="errorMsg">
                            {this.state.errors.district}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <hr />
                    {/* -----------phase I--------- */}
                    <div className="row">
                      <div className="form-group col-sm-3">
                        <label> Region</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="region"
                          className="form-control input-placeholder"
                          value={this.state.region}
                          onChange={this.handleInputState}
                          placeholder="region"
                        ></input>
                        {this.state.errors.region ? (
                          <div className="errorMsg">
                            {this.state.errors.region}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> Mobile No</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="phone"
                          className="form-control input-placeholder"
                          value={this.state.phone}
                          onChange={this.handleInputState}
                          placeholder="phone"
                        ></input>
                        {this.state.errors.phone ? (
                          <div className="errorMsg">
                            {this.state.errors.phone}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> Email</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="email"
                          className="form-control input-placeholder"
                          value={this.state.email}
                          onChange={this.handleInputState}
                          placeholder="email"
                        ></input>
                        {this.state.errors.email ? (
                          <div className="errorMsg">
                            {this.state.errors.email}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <span style={{ color: "green", fontWeight: "bold" }}>
                    Your Next Of Kin Personal Details
                  </span>
                  {/* ----phase II-- */}
                  <div className="block login">
                    <div className="row">
                      <div className="form-group col-sm-3">
                        <label> Next Of Kin Full Name</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="fullname"
                          className="form-control input-placeholder"
                          value={this.state.kin_name}
                          onChange={this.handleInputState}
                          placeholder="fullname"
                        ></input>
                        {this.state.errors.fullname ? (
                          <div className="errorMsg">
                            {this.state.errors.fullname}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> Relationship</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="relationship"
                          className="form-control input-placeholder"
                          value={this.state.relationship}
                          onChange={this.handleInputState}
                          placeholder="relationship"
                        ></input>
                        {this.state.errors.relationship ? (
                          <div className="errorMsg">
                            {this.state.errors.relationship}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> Mobile Phone</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="phone2"
                          className="form-control input-placeholder"
                          value={this.state.phone2}
                          onChange={this.handleInputState}
                          placeholder="mobile"
                        ></input>
                        {this.state.errors.phone2 ? (
                          <div className="errorMsg">
                            {this.state.errors.phone2}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <hr />
                    {/* ------phase III------ */}
                    <div className="row">
                      <div className="form-group col-sm-3">
                        <label> Address</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="address2"
                          className="form-control input-placeholder"
                          value={this.state.address2}
                          onChange={this.handleInputState}
                          placeholder="address"
                        ></input>
                        {this.state.errors.address2 ? (
                          <div className="errorMsg">
                            {this.state.errors.address2}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> District</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="district2"
                          className="form-control input-placeholder"
                          value={this.state.district_2}
                          onChange={this.handleInputState}
                          placeholder="district"
                        ></input>
                        {this.state.errors.district2 ? (
                          <div className="errorMsg">
                            {this.state.errors.district2}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-sm-3">
                        <label> Region</label>
                        <font className="label-comment">*</font>
                        <input
                          type="text"
                          name="region2"
                          className="form-control input-placeholder"
                          value={this.state.region2}
                          onChange={this.handleInputState}
                          placeholder="district"
                        ></input>
                        {this.state.errors.region2 ? (
                          <div className="errorMsg">
                            {this.state.errors.region2}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="form-group col-sm-3">
                      <Button
                        type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        // onClick={}
                      >
                        Save <FaSave size={20} />
                      </Button>
                      {/* <Button
                        // type="submit"
                        className="btn btn-round  btn-md"
                        variant="primary"
                        // onClick={}
                      >
                        Next <FaArrowAltCircleRight size={20} />
                      </Button> */}
                    </div>
                    <div className="form-group col-sm-3"></div>
                  </div>
                </form>
              </>
              {/* )} */}
            </div>
          </div>
        </div>
        {/* </div>
        </Container> */}
      </React.Fragment>
    );
  }
}

export default PersonalDetails;
