import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import { FaRegHandPointDown, FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import changeStage from "../../../util/changeStage";
// import ReactLoading from "react-loading";

class FeePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info_loading: null,
      network_error: false,
      control_number: "",
      isPaid: 0,
      stage: 1,
      programCode: "",
    };
  }

  componentDidMount() {
    this.getControlNumber();
    if (localStorage.getItem("program") == "Bachelor of Accountancy") {
      this.setState({ programCode: "BAC" });
    } else if (
      localStorage.getItem("program") ==
      "Bachelor Degree in Education with Accounting and Business Studies"
    ) {
      this.setState({ programCode: "BED-ABS" });
    } else {
    }
  }

  getControlNumber = async () => {
    this.setState({ info_loading: true });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      platform: "ors",
    };

    //the following 3 lines should be commented after testing/video recording
    // this.setState({
    //   control_number: 994070432719,
    //   isPaid: 1,
    //   //I added these two
    //   info_loading: false,
    //   network_error: false,
    // });

    await api
      .post(params.CONTROL_NUMBER, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              control_number: res.data.data[0].controlNumber,
              expireDate: res.data.data[0].isExpired,
              isPaid: res.data.data[0].isPaid,
              info_loading: false,
              network_error: false,
            });
          } else if (res.data.stsCode === "0001") {
            alert(res.data.stsMessage);
          }
        } else {
          this.setState({
            control_number: "",
            info_loading: false,
            network_error: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ network_error: true, info_loading: true });
      });

    //this was previuosly in catch phrase
    //this.setState({ network_error: false, info_loading: true });
  };

  nextToPersonalDetails = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 11,
    };
    if (parseInt(localStorage.getItem("stageid")) === 10) {
      let result = await changeStage(payLoad);
      if (result === 1) {
        this.setState({ loading: false, network_error: false });
        this.props.history.push("/registration-personal");
      } else {
      }
    } else {
      this.props.history.push("/registration-personal");
    }
  };

  render() {
    // console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-content">
            <div className="card">
              <div className="card-header">
                <div className="float-left card-header-title">
                  Registration Fee Payment
                </div>
                <div className="float-right card-header-title">
                  Application for : {localStorage.getItem("id")}
                </div>
              </div>
              <div className="card-block">
                <div className="block">
                  {this.state.stage === 1 ? (
                    <div className="row">
                      <div className="col-sm-12">
                        <p>
                          <b>Instructions</b>
                        </p>
                        <hr />
                        {this.state.info_loading === true ? (
                          <div className="row">
                            <div className="col-sm-6"></div>
                            <div className="col-sm-2">
                              {/* <ReactLoading type="cylon" color="#000" /> */}
                            </div>
                            <div className="col-sm-6"></div>
                          </div>
                        ) : null}

                        {this.state.network_error === true ? (
                          <>
                            <div className="row">
                              <div className="col-sm-1"></div>
                              <div className="col-sm-10">
                                <div className="alert alert-warning">
                                  <strong>Warning!</strong> You are facing a
                                  connection problem, please contact the system
                                  administrator OR try later
                                </div>
                              </div>
                              <div className="col-sm-1"></div>
                            </div>
                          </>
                        ) : (
                          <>
                            {this.state.isPaid === 0 ? (
                              <>
                                <ul>
                                  Please use the control number below to pay for
                                  Registration fees{" "}
                                  <FaRegHandPointDown size={20} />
                                  <li
                                    style={{ paddingTop: "8px", color: "red" }}
                                  >
                                    Registration Fee Control Number :{" "}
                                    {this.state.control_number}
                                  </li>
                                  <li
                                    style={{
                                      paddingTop: "8px",
                                      color: "black",
                                    }}
                                  >
                                    {/* <>Minimum amount to be paid : 150,000 TSH</> */}

                                    {/* {parseInt(
                                      localStorage.getItem("awardId")
                                    ) == 4 ? (
                                      <>Amount to be paid : 500,000 TSH</>
                                    ) : (
                                      <>Amount to be paid : 100,000 TSH</>
                                    )} */}

                                    {parseInt(
                                      localStorage.getItem("awardId")
                                    ) === 1 ? (
                                      <>
                                        Minimum amount to be paid : 346,900 TSH
                                      </>
                                    ) : (
                                      <>
                                        {parseInt(
                                          localStorage.getItem("awardId")
                                        ) === 2 ? (
                                          <>
                                            Minimum amount to be paid : 367,900
                                            TSH
                                          </>
                                        ) : (
                                          <>
                                            {parseInt(
                                              localStorage.getItem("awardId")
                                            ) === 3 ? (
                                              <>
                                                {this.state.programCode ==
                                                "BAC" ? (
                                                  <>
                                                    Minimum amount to be paid :
                                                    431,400 TSH
                                                  </>
                                                ) : (
                                                  <>
                                                    {this.state.programCode ==
                                                    "BED-ABS" ? (
                                                      <>
                                                        Minimum amount to be
                                                        paid : 452,400 TSH
                                                      </>
                                                    ) : (
                                                      <>
                                                        Minimum amount to be
                                                        paid : 410,400 TSH
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {parseInt(
                                                  localStorage.getItem(
                                                    "awardId"
                                                  )
                                                ) === 4 ? (
                                                  <>
                                                    Minimum amount to be paid :
                                                    500,000 TSH
                                                  </>
                                                ) : (
                                                  <>
                                                    {parseInt(
                                                      localStorage.getItem(
                                                        "awardId"
                                                      )
                                                    ) === 5 ? (
                                                      <>
                                                        Minimum amount to be
                                                        paid : 460,000 TSH
                                                      </>
                                                    ) : (
                                                      <>No amount specified</>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </li>
                                </ul>
                              </>
                            ) : (
                              <>
                                {/* Added New---Control numbers details */}
                                <>
                                  <ul>
                                    Please use the control number below to pay
                                    for Registration fees{" "}
                                    <FaRegHandPointDown size={20} />
                                    <li
                                      style={{
                                        paddingTop: "8px",
                                        color: "red",
                                      }}
                                    >
                                      Registration Fee Control Number :{" "}
                                      {this.state.control_number}
                                    </li>
                                    <li
                                      style={{
                                        paddingTop: "8px",
                                        color: "black",
                                      }}
                                    >
                                      {/* <>Minimum amount to be paid : 150,000 TSH</> */}

                                      {/* {parseInt(
                                      localStorage.getItem("awardId")
                                    ) == 4 ? (
                                      <>Amount to be paid : 500,000 TSH</>
                                    ) : (
                                      <>Amount to be paid : 100,000 TSH</>
                                    )} */}

                                      {parseInt(
                                        localStorage.getItem("awardId")
                                      ) === 1 ? (
                                        <>
                                          Minimum amount to be paid : 346,900
                                          TSH
                                        </>
                                      ) : (
                                        <>
                                          {parseInt(
                                            localStorage.getItem("awardId")
                                          ) === 2 ? (
                                            <>
                                              Minimum amount to be paid :
                                              367,900 TSH
                                            </>
                                          ) : (
                                            <>
                                              {parseInt(
                                                localStorage.getItem("awardId")
                                              ) === 3 ? (
                                                <>
                                                  {this.state.programCode ==
                                                  "BAC" ? (
                                                    <>
                                                      Minimum amount to be paid
                                                      : 431,400 TSH
                                                    </>
                                                  ) : (
                                                    <>
                                                      {this.state.programCode ==
                                                      "BED-ABS" ? (
                                                        <>
                                                          Minimum amount to be
                                                          paid : 452,400 TSH
                                                        </>
                                                      ) : (
                                                        <>
                                                          Minimum amount to be
                                                          paid : 410,400 TSH
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {parseInt(
                                                    localStorage.getItem(
                                                      "awardId"
                                                    )
                                                  ) === 4 ? (
                                                    <>
                                                      Minimum amount to be paid
                                                      : 500,000 TSH
                                                    </>
                                                  ) : (
                                                    <>
                                                      {parseInt(
                                                        localStorage.getItem(
                                                          "awardId"
                                                        )
                                                      ) === 5 ? (
                                                        <>
                                                          Minimum amount to be
                                                          paid : 460,000 TSH
                                                        </>
                                                      ) : (
                                                        <>No amount specified</>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </li>
                                  </ul>
                                </>
                                {/* Added New---Control numbers details */}
                                <ul>
                                  Please click the button below to go to the
                                  next step.
                                  <li
                                    style={{ paddingTop: "8px", color: "red" }}
                                  >
                                    To continue please click the button below{" "}
                                    <FaRegHandPointDown size={20} />
                                  </li>
                                </ul>
                                <Button
                                  type="submit"
                                  className="btn btn-round  btn-md"
                                  variant="primary"
                                  onClick={this.nextToPersonalDetails.bind(
                                    this
                                  )}
                                >
                                  Continue to Personal Details{" "}
                                  <FaArrowAltCircleRight size={20} />
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <br />
                    <strong>Note that:</strong> You can not continue with
                    registration process unless you have paid the required
                    Registration Fee <br />
                    <br />
                    Follow the procedures below to make payments using Bank,
                    M-Pesa, Tigo Pesa, Airtel money, T-Pesa etc. as indicated
                    bellow.
                  </div>
                  <br />
                  <br />
                  <strong>A. Bank: Follow these steps to pay</strong>
                  <ol>
                    <li>
                      Record your control number and visit NMB or CRDB to make
                      payments{" "}
                    </li>
                    <li>
                      Upon your arrival at any of the banks shown above, provide
                      the control number to a teller to process the payment
                    </li>
                  </ol>
                  <strong>B. M-Pesa: Follow these steps to pay</strong>
                  <ol>
                    <li>Dial *150*00#</li>
                    <li>Select 4 (Pay Bill) </li>
                    <li>Select 5 (Government Payment)</li>
                    <li>Select 1 (Control Number) </li>
                    <li>Enter Reference Number (Enter Control Number Here)</li>
                    <li>Enter amount (Enter the amount you want to pay) </li>
                    <li>Enter Password (Enter your account Password)</li>
                    <li>Enter 1 (To agree)</li>
                  </ol>
                  <strong>C. Tigo Pesa: Follow these steps to pay</strong>
                  <ol>
                    <li>Dial *150*01#</li>
                    <li>Select 4 (Pay Bill) </li>
                    <li>Select 5 (Government Payments)</li>
                    <li>Enter Reference Number (Enter Control Number)</li>
                    <li>Enter amount (Enter amount you want to pay) </li>
                    <li>Enter Password (Enter your account Password)</li>
                  </ol>
                  <strong>D. Airtel Money: Follow these steps to pay</strong>
                  <ol>
                    <li>Dial *150*60#</li>
                    <li>Select 5 (Make Payments) </li>
                    <li>Select 3 (Government Payments)</li>
                    <li>Enter amount (Enter amount you want to pay) </li>
                    <li>Enter Reference Number (Enter Control Number)</li>
                    <li>Enter Password (Enter your account Password)</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default FeePayment;
