import React from "react";

export default function useUnitQuery(action, opts, deps) {
  const ref = React.useRef({});
  const [params, setParams] = React.useState({ ...opts?.defaultParams });
  const [data, setData] = React.useState(opts?.defaultValue);
  ref.current.opts = opts;
  ref.current.action = action;
  ref.current.deps = deps;
  const load = React.useCallback(
    ($params) => {
      const next_params = { ...params, ...ref.current.next_params, ...$params };
      ref.current.next_params = next_params;
      setParams((p) => ({ ...p, ...next_params }));
      if (
        Array.isArray(ref.current.deps) &&
        ref.current.deps.filter((d) => ref.current.next_params[d] == undefined)
          .length
      ) {
        return Promise.resolve(ref.current.opts?.defaultValue);
      }
      return Promise.resolve(ref.current.action(ref.current.next_params)).then(
        (res) => {
          setData(res?.data?.data);
          return res?.data?.data;
        }
      );
    },
    [setParams, setData, ref]
  );
  React.useEffect(() => {
    if (opts?.prefech !== false) load(opts?.params);
  }, [opts?.prefech, opts?.params]);
  return { ok: !!data, data, params, setParams, load };
}
