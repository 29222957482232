import React from 'react';
import DetailTable from './detail-table'

const StudentFeeDetails = ({details}) => {
  const regNo = localStorage.getItem("registrationNo");
  const adminNo = localStorage.getItem("admission");
  const status = details.status==0 ? 'Has no Loan' : 'Has Loan';
  // console.log(details.status);
  const studentNo = `${regNo} - ${adminNo}`

  const ROWS = [
    { label:'Registration Number', value: regNo },
    { label:'Admission Number', value: adminNo },
    { label:'Loan Status', value: status },
    { label:'HESLB Loan Amount', value: details.loan },
    { label:'Total Debited Amount', value: details.invoice},
    { label:'Total Paid Amount', value:details.paidAmt },
    { label:'Total Amount Due', value: details.amountDue },
  ]

  const ROW_CONTROL_NUMBER = [
    { label:'Total Tuition Fee:', value:"" },
    { label:'Loan Amount:', value:""},
    { label:'Semester Amount:', value:"" },
    { label:'Control Number : ', value:"" },
  ]

  return (
      <> 
      {/* <DetailTable rows={ROW_CONTROL_NUMBER} />  */}
      <br />
      <DetailTable rows={ROWS} /> 
      </>
  )
}

export default StudentFeeDetails
