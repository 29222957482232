import React, { useState, Component } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import BaseSelect from "helpers/Select";
import BaseDialog from "helpers/BaseDialog";
import {} from "./services";
import Fetcher from "helpers/Fetcher";
import Grid from "@material-ui/core/Grid";
// import Button from "@material-ui/core/Button";
import Button from "@material-ui/core/Button";
import BaseTextField from "helpers/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

const APIS = {
  ACADEMIC_YEARS_API: "/api/academic_year/all",
  PROGRAMMES: "/api/program/all?size=100",
  CAMPUSES: "/api/lookup/campus/list",
  INVOICE_TYPE: "/api/finance/invoice_type/all",
  INTAKE: "/api/intake/all",
  AWARDS: "/api/awards/all",
  FEE_ITEMS: "/api/finance/payment_item/all",
};
const InvoiceType = [
  { name: "ID card", id: "0" },
  { name: "Carry", id: "1" },
];

const INITIAL_STATE = {
  awards: [],
  program: "",
  campus: "",
  intakeId: "",
  startDate: "",
  endDate: "",
  isViewReport: false,
};

const FORM_VALIDATION = Yup.object().shape({
  yos: Yup.string().required("Please select year of study"),
});

const GenerateControlNumber = () => {
  const [state, setState] = useState(INITIAL_STATE);

  const closeDialog = () => {
    return setState((prevState) => ({
      ...prevState,
      isViewReport: false,
    }));
  };

  const handleSearch = async (helpers, values) => {
    let payload = {
      campusId: values.campus,
      programId: values.program,
      intakeId: values.intakeId,
      awardId: values.awardId,
      yearOfStudy: values.yos,
      dateFrom: values.startDate,
      dateTo: values.endDate,
      reportType: values.type,
    };
    // gepgcollection_details(payLoad).then((res) => {
    //   if (res.status === 200) {
    //     if (res.data.stsCode === "0000") {
    //       if (res.data.data.length) {
    //         setState((prevState) => ({
    //           ...prevState,
    //           viewReport: res.data.data,
    //           isViewReport: true,
    //         }));
    //         helpers.resetForm();
    //       }
    //       helpers.resetForm();
    //     }
    //   } else {
    //     return;
    //   }
    // });
    /*
        const res = await gepgcollection_details(payload);
        if (res.status === 200) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "gepg_collection_report.csv");
            document.body.appendChild(link);
            link.click();
            helpers.resetForm();
        } else {
            helpers.resetForm();
        }
        */
  };
  return (
    <>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <h4>Generate control number/invoice for Payment</h4>
          <Formik
            initialValues={INITIAL_STATE}
            validationSchema={FORM_VALIDATION}
            onSubmit={(helpers, values) => handleSearch(values, helpers)}
          >
            {({ isValid, dirty, isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <BaseSelect
                      style={{ marginBottom: "10px" }}
                      name="yos"
                      label="Select Type of invoice"
                      options={InvoiceType}
                    />
                  </Grid>

                  <Grid container spacing={2} style={{ marginTop: "5px" }}>
                    <Grid item xs>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        disabled={!dirty || !isValid}
                        type="submit"
                      >
                        Generate Control Number
                      </Button>
                    </Grid>
                    <Grid item>
                      {/* <Button
                                                disabled={!dirty || !isValid || isSubmitting}
                                                type="submit"
                                                color="primary"
                                                startIcon={
                                                    isSubmitting ? (
                                                        <CircularProgress size="1rem" />
                                                    ) : undefined
                                                }
                                                label={isSubmitting ? "Downloading..." : "Download"}
                                            /> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <BaseDialog
        fullWidth
        maxWidth={"md"}
        openDialog={state.isViewReport}
        setOpenDialog={() => closeDialog()}
        title="VIEW REPORT"
      >
        <embed
          width={925}
          height={925}
          src={`data:application/pdf;base64,${state.viewReport}`}
        />
      </BaseDialog>
    </>
  );
};

export default GenerateControlNumber;
