import { Route } from "react-router-dom";
import ClearanceView from "./clearance-view";
import ClearanceRequest from "./clearance-request";

function Paths() {
  return (
    <>
      <Route path="/clearance" exact component={ClearanceView} />
      <Route path="/clearance/request" exact component={ClearanceRequest} />
    </>
  );
}
const routes = [
  {
    path: "/clearance",
    exact: true,
    component: Paths,
  },
];


export default routes;
