
import React from "react";

export function parseStringTemplate(template, obj) {
  if (!obj) return "";
  if(template){
    const tem = template.replace("}", "").replace("{", "");
    if(React.isValidElement(obj?.[tem])){
      return obj[tem];
    }
  }
  // Use a regular expression to find placeholders enclosed in curly braces
  const regex = /\{([a-zA-Z0-9_.]+)\}/g;
  return template.replace(regex, (match, property) => {
    // Check if the property exists in the object
    const items = property.split(".");
    let value = obj;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (value.hasOwnProperty(item)) {
        value = value?.[item];
      } else {
        return `[Missing property: ${property}]`; // Example handling
      }
    }
    return String(value)
  });
}
