import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { Route } from "react-router-dom";
import changeStage from "../../../util/changeStage";
import Header from "helpers/Header/Header";

import ResponseForm from "./request-controlno";
import ValidateNhifCard from "./validate-nhif-card";
import { nhifGeneralStatus } from "./services";
import ApplyNhifCard from "./apply-nhif-card";
import DisplayNhifControlno from "./display-nhif-controlno";

import "./nhif.css";

const INITIAL_STATE = {
  stage: "",
  isVerified: "",
  isWaived: "",
  isRegistered: "",
  isCardApplied: "",
  controlNo: "",
  isPaid: "",
};
const Home = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [state, setState] = useState(INITIAL_STATE);
  let history = useHistory();

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      stage: parseInt(localStorage.getItem("stageid")),
    }));
    checkNhifGeneralStatus();
  }, []);

  const checkNhifGeneralStatus = (helpers, values) => {
    let payLoad = {
      admno: localStorage.getItem("id"),
    };

    // setState((prevState) => ({
    //   ...prevState,
    //   isVerified: 0,
    //   isWaived: 0,
    //   isRegistered: 0,
    //   isCardApplied: 1,
    //   controlNo: 0,
    //   isPaid: 0,
    // }));

    nhifGeneralStatus(payLoad).then((res) => {
      if (res.status === 200) {
        setState((prevState) => ({
          ...prevState,
          isVerified: res.data.isVerified,
          isWaived: res.data.isWaived,
          isRegistered: res.data.isRegistered,
          isCardApplied: res.data.isCardApplied,
          controlNo: res.data.controlNo,
          isPaid: res.data.isPaid,
        }));
      } else {
        return;
      }
    });
  };
  const moveNextStageCheck = async (helpers, values) => {
    let payLoadStage = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 15,
    };

    if (parseInt(localStorage.getItem("stageid")) === 14) {
      let result = await changeStage(payLoadStage);
      if (result === 1) {
        setState({ loading: false, network_error: false });
        history.push("/registration-declaration");
      } else {
      }
    } else {
      history.push("/registration-declaration");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card">
          <div className="card-header">
            <Header title="NHIF Module" />
          </div>
          <div className="card-body exam-card">
            {state.stage < 14 ? (
              <>
                {" "}
                {/* <div className="page-content"> */}
                <div className="card">
                  <div className="card-header">
                    <div className="float-left card-header-title">
                      {/* Completed */}
                    </div>
                    <div className="float-right card-header-title">
                      Application Number : {localStorage.getItem("id")}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="block">
                      <div className="alert alert-warning">
                        {/* <Alert severity="warning"> */}
                        Please click next in the previous stage
                        {/* {console.log(this.state.stage)} */}
                        {/* </Alert> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </>
            ) : (
              <>
                {state.isVerified == 1 ||
                state.isPaid == 1 ||
                state.isWaived == 1 ? (
                  <>
                    <h6>
                      Your NHIF status is{" "}
                      <span
                        style={{
                          color: "green",
                          fontSize: "18px",
                        }}
                      >
                        "CLEAR"{" "}
                      </span>
                      Click the below button to continue to next stage.
                    </h6>
                    <Formik
                      initialValues={INITIAL_STATE}
                      onSubmit={(helpers, values) =>
                        moveNextStageCheck(values, helpers)
                      }
                    >
                      {({ isValid, dirty }) => (
                        <Form className="form-inline">
                          <>
                            <button
                              style={{ marginTop: "20px" }}
                              className="btn btn-round  btn-md btn-primary form-inline-button"
                              type="submit"
                              name="submit"
                            >
                              Continue to next stage
                            </button>
                          </>
                          <br />
                        </Form>
                      )}
                    </Formik>
                  </>
                ) : (
                  <>
                    {
                      // state.isRegistered === 1 &&
                      // state.isCardApplied === 0 &&
                      // state.isVerified === 0 &&
                      // state.isPaid === 0 &&
                      // state.isWaived === 0
                      state.controlNo != null ? (
                        <>
                          <h6>
                            Your NHIF status is{" "}
                            <span
                              style={{
                                color: "green",
                                fontSize: "18px",
                              }}
                            >
                              "CARD APPLICATION SUCCESSFUL"
                            </span>
                          </h6>
                          {/* <ApplyNhifCard /> */}
                          <DisplayNhifControlno />
                        </>
                      ) : (
                        <>
                          {
                            // state.isRegistered === 0 &&
                            // state.isCardApplied === 1 &&
                            // state.isVerified === 0 &&
                            // state.isPaid === 0 &&
                            // state.isWaived === 0
                            state.isCardApplied == 1 ? (
                              <>
                                <h6>
                                  Your NHIF status is{" "}
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "18px",
                                    }}
                                  >
                                    "SUCCESSFULL CARD APPLICATION"
                                  </span>
                                </h6>
                                <DisplayNhifControlno />
                              </>
                            ) : (
                              <>
                                {
                                  // state.isRegistered === 0 &&
                                  // state.isCardApplied === 1 &&
                                  // state.isVerified === 0 &&
                                  // state.isPaid === 0 &&
                                  // state.isWaived === 0
                                  state.isRegistered == 1 ? (
                                    <>
                                      <ApplyNhifCard />
                                    </>
                                  ) : (
                                    <>
                                      <div className="block">
                                        {/* {console.log(state.isRegistered)};
                                        {console.log(state.isCardApplied)};
                                        {console.log(state.controlNo)}; */}
                                        <Grid container>
                                          <Grid item xs={2}>
                                            <Paper
                                              elevation={0}
                                              style={{
                                                height: "100%",
                                                borderRight: "1px solid #ccc",
                                              }}
                                            >
                                              <Tabs
                                                value={selectedTab}
                                                orientation="vertical"
                                                onChange={handleChange}
                                                variant="scrollable"
                                                scrollButtons="auto"
                                              >
                                                <Tab
                                                  component={Link}
                                                  to="/registration-nhif"
                                                  label="NHIF"
                                                />
                                                <Divider />
                                                {/* <Tab component={Link} to="/nhif/validate"  label="VALIDATE NHIF CARD"  />
                                      <Divider /> */}
                                              </Tabs>
                                            </Paper>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={10}
                                            style={{ padding: "15px" }}
                                          >
                                            <Route
                                              exact
                                              path="/registration-nhif"
                                              component={ResponseForm}
                                            />
                                            {/* <Route path="/nhif/validate" component={ValidateNhifCard} /> */}
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
