import React, { useEffect, useState } from "react";
import axios from "api/axios";

const result = {
  loading: true,
  json: [],
  defaultValue: undefined,
};
const types = ["listdata", "data", "items"];
const getResponseProperty = async (response) => {
  for (let t of types) {
    if (t in response) {
      const found = response[t];
      return Array.isArray(found) ? found : [];
    }
  }
  return Array.isArray(response) ? response : [];
};

const Fetcher = ({ api, render, getDefaultValue, defaultItems }) => {
  const [response, setResponse] = useState(result);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      // get data from api
      const resp = await axios.get(api);
      if (!resp?.data) return;
      // convert data to json
      const response = await getResponseProperty(resp.data);
      const loading = false;
      const defaultValue = getDefaultValue?.(response);

      const json = defaultItems ? [...defaultItems, ...response] : response;
      // set state
      if (isSubscribed) {
        setResponse({
          loading,
          json,
          defaultValue,
        });
      }
    };

    // call the function
    fetchData().catch((err) => console.log(err));

    // cancel any future setResponse
    return () => (isSubscribed = false);
  }, [api]);

  return <div>{render(response)}</div>;
};

export default Fetcher;
