import React, { Component } from "react";
import api from "../../util/api";
import params from "../../util/api_routes/params";

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      selectedFile: null,
      setIsSelected: false,
      base64Content: "",
      loading: "",
      fields: {},
      errors: {},
      photo: "",
    };
    this.uploadSingleFile = this.uploadSingleFile.bind(this);
    this.upload = this.upload.bind(this);
  }

  componentDidMount() {
    this.fetchImage();
  }

  uploadSingleFile = (e) => {
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),
    });
    if (e.target.files < 1 || !e.target.validity.valid) {
      return;
    }
    let file = e.target.files[0];
    if (file.size > 1 * 1000 * 512) {
      alert(
        "Your file size is greater than 500 KB, please upload less than that."
      );
    } else {
      this.fileToBase64(file, (err, result) => {
        if (result) {
          let data = result.split(",")[1];
          this.setState({
            selectedFile: file,
            setIsSelected: true,
            isSelected: true,
            base64Content: data,
          });
        }
      });
    }

    this.setState({
      selectedFile: e.target.files[0],
      setIsSelected: true,
      isSelected: true,
    });
  };

  fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  upload = async (e) => {
    e.preventDefault();
    // console.log(this.state.file);
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      awardId: 2,
      photo: this.state.base64Content,
    };
    await api
      .post(params.UPLOAD_PHOTO, payLoad)
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              yesIsOpen: false,
              noIsOpen: false,
              loading: false,
              network_error: false,
            });

            window.location.reload();
          } else {
            this.setState({
              network_error: true,
              answer: "",
              loading: false,
            });
          }
          ///
        } else {
          this.setState({
            network_error: true,
            answer: "",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, answer: "", loading: false });
      });
  };

  fetchImage = async () => {
    // e.preventDefault();
    // console.log(this.state.file);
    this.setState({ loading: true, network_error: false });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };

    // const admno = parseInt(localStorage.getItem("id"));

    // const imageUrl = `http://10.1.90.83/studentis/photos/${admno}.jpg`;
    // const imageUrl = `http://localhost/studentis/${applicationProfileId}.jpg`;

    // this.setState({
    //   photo: imageUrl,
    // });

    await api
      .post(params.FETCH_PHOTO, payLoad)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              photo: res.data.data.photo,
              // photo: imageUrl,
              yesIsOpen: false,
              noIsOpen: false,
              loading: false,
              network_error: false,
            });
          } else {
            this.setState({
              network_error: true,
              answer: "",
              loading: false,
            });
          }
        } else {
          this.setState({
            network_error: true,
            answer: "",
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ network_error: true, answer: "", loading: false });
      });
  };

  render() {
    let imgPreview;
    if (this.state.file) {
      imgPreview = (
        <img src={this.state.file} alt="" style={{ width: 200, height: 250 }} />
      );
    }
    let imgReal;
    if (this.state.photo !== 0) {
      // if (this.state.photo) {
      // console.log(this.state.photo);
      imgReal = (
        <img
          src={`data:image/jpeg;base64,${this.state.photo}`}
          alt=""
          style={{ width: 200, height: 250 }}
        />
        // <img src={this.state.photo} alt="" style={{ width: 200, height: 250 }} />
      );
    }

    return (
      <form>
        <div className="form-group preview">{imgPreview}</div>
        <div className="form-group preview">{imgReal}</div>
        <div className="form-group">
          <input
            type="file"
            name="file"
            className="form-control"
            onChange={this.uploadSingleFile}
            multiple
            accept="image/*"
          />
          {this.state.isSelected === true ? (
            <div>
              <p>Filename: {this.state.selectedFile.name}</p>
            </div>
          ) : (
            <p>Attach recent passport size (Maximum size is 500 KB)</p>
            // <p>Select an Image</p>
          )}
          {this.state.errors.file ? (
            <div className="errorMsg">{this.state.errors.file}</div>
          ) : null}
        </div>
        <button
          type="button"
          className="btn btn-primary btn-block"
          onClick={this.upload}
        >
          Upload
        </button>
      </form>
    );
  }
}

export default UploadImage;
