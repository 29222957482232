import React, { Component } from "react";
import { Container, Alert } from "reactstrap";

class AfterDeclation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  render() {
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          {this.state.stage < 16 ? (
            <>
              {" "}
              <div className="page-content">
                <div className="card">
                  <div className="card-header">
                    <div className="float-left card-header-title">
                      {/* Completed */}
                    </div>
                    <div className="float-right card-header-title">
                      Application Number : {localStorage.getItem("id")}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="block">
                      <div className="alert alert-warning">
                        {/* <Alert severity="warning"> */}
                        Please complete the previous stage by clicking the
                        "Submit" button
                        {/* {console.log(this.state.stage)} */}
                        {/* </Alert> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="page-content">
              <div className="card">
                <div className="card-header">
                  <div className="float-left card-header-title">Completed</div>
                  <div className="float-right card-header-title">
                    Application Number : {localStorage.getItem("id")}
                  </div>
                </div>
                <div className="card-body">
                  <div className="block">Completed</div>
                </div>
              </div>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  }
}
export default AfterDeclation;
