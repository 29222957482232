import axios from "axios";
import store from "store";
import { showSnackbar } from "store/snackbar/actions";
import { showPreloader, hidePreloader } from "store/preloader/actions";
const BASE_URL = process.env.APP_SERVER_URL;

axios.defaults.baseURL = BASE_URL;
//axios.defaults.headers.common["Authorization"] = TOKEN !== null ? `Bearer ${TOKEN}` : null;

axios.defaults.headers.common["Accept"] = `application/json`;
axios.defaults.headers.common["Content-Type"] = `application/json`;

export const SNACKBAR_SEVERITY = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  SUCCESS: "success",
};
const getToken = () => {
  return JSON.parse(localStorage.getItem("token"));
};

const requestHandler = async (requestConfig) => {
  const { dispatch } = store;
  dispatch(showPreloader());
  return requestConfig;
};
const handle401 = (error) => {
  const { dispatch } = store;
  if (error.response.data.message != "Session expired") {
    const payload = {
      title: `${error.code}`,
      message: `${error.response.data.message}`,
      severity: SNACKBAR_SEVERITY.ERROR,
    };
    dispatch(hidePreloader());
    dispatch(showSnackbar(payload));
  }
};

const successHandler = async (responseObject) => {
  const { dispatch } = store;
  const payload = {
    message: responseObject?.data?.message || "Success",
    severity: SNACKBAR_SEVERITY.SUCCESS,
  };

  switch (responseObject.config?.method) {
    case "put":
    case "post":
    case "delete":
      dispatch(showSnackbar(payload));
      break;
    default:
      break;
  }
  dispatch(hidePreloader());
  return responseObject;
};

const errorHandler = (error, status) => {
  const { dispatch } = store;
  switch (status) {
    case 401:
      handle401(error);
      break;
    default:
      const message =
        error.response?.data?.["errors"] ??
        error.response?.data?.message ??
        "An error occured";

      const payload = {
        title: `${error.message}`,
        message: message,
        severity: SNACKBAR_SEVERITY.ERROR,
      };
      dispatch(hidePreloader());
      dispatch(showSnackbar(payload));
  }
  console.log(error)
  return {
    data: null,
  };
};

axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    const { url, method } = config;
    const isALoginAttempt = method === "post" && url === "/api/oauth/token";
    if (token && !isALoginAttempt) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return requestHandler(config);
  },
  (error) => {
    const STATUS = error?.response?.status;
    return errorHandler(error, STATUS);
  },
);
axios.interceptors.response.use(
  (response) => {
    return successHandler(response);
  },
  (error) => {
    const STATUS = error?.response?.status;
    return errorHandler(error, STATUS);
  },
);

//axios.defaults.validateStatus = (_status) => true;
export default axios;
