import { TextField } from "@material-ui/core";

export default function TextBox({ label, value, ...props }) {
  return (
    <TextField
      readOnly
      inputProps={{ readOnly: "read-only" }}
      value={value}
      fullWidth
      label={label}
      variant="standard"
      {...props}
    />
  );
}
