import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";

import { getUrlParamValue } from "util/get-query-params";
import {
  getStudentModuleResults,
} from "modules/examination-results/services";
import { DataTable } from "helpers/Tables";

const STATE = {
  rows: [],
  isDialogOpen: false,
};

const FORM_DATA = {
  academicYear: "",
  campusId: "",
  programmeId: "",
  year: "",
  inTake: "",
  semesterId: "",
};

const HEADERS = [
  { field: "entryCode", label: "Code" },
  { field: "moduleName", label: "Module Name" },
  { field: "ca", label: "CA" },
  { field: "se", label: "SE" },
  { field: "tot", label: "Total" },
  { field: "grade", label: "Grade" },
  { field: "defination", label: "Definition" },
];

const StudentModuleResult = ({ history }) => {
  const [state, setState] = useState(STATE);
  const [formData, setFormData] = useState(FORM_DATA);

  const getCoursworkData = (evt, entry) => {

  };

  const generateLink = (entry) => {
    return (
      <>
        {/* <a
          style={{ textDecoration: "underline !important" }}
          href="http://#"
          // onClick={(event) => getCoursworkData(event, entry)}
        > */}
          {`${entry.code}`}
        {/* </a> */}
      </>
    );
  };

  const generateEntriesData = (entries) => {
    // return entries.map((entry) => ({
    //   ...entry,
    //   entryCode: generateLink(entry),
    // }));
    return entries
    ? entries.map((entry, idx) => ({
        ...entry,
        entryCode: generateLink(entry, idx),
      }))
    : [];
  };

  const generateData = (results) => {
    return results.map((entry) => ({
      label: (entry[0].charAt(0).toUpperCase() + entry[0].slice(1)).match(
        /[A-Z][a-z]+/g,
      ).join(" "),
      entries: generateEntriesData(entry[1]),
    }));
  };

  const getStudentResults = async () => {
    const params = {
      regNo: getUrlParamValue.admno,
      academicYear: getUrlParamValue.academicYear,
      year: getUrlParamValue.year,
    };

    getStudentModuleResults(params).then((response) => {
      setState((prevState) => ({
        ...prevState,
        rows: generateData(Object.entries(response.data.data)),
      }));
    });
  };

  useEffect(() => {
    if(!history?.location?.params?.rows) {
      console.error("empty history.location.params.rows")
      return;
    }
    const params = history.location.params;
    const gpa_sem_one = history.location.gpa_sem_one;
    const gpa_sem_two = history.location.gpa_sem_two;
    const remark_sem_one = history.location.remark_sem_one;
    const remark_sem_two = history.location.remark_sem_two;
    if (params && Object.entries(history.location.params.rows)) {
      setState((prevState) => ({
        ...prevState,
        rows: generateData(Object.entries(history.location.params.rows)),
        gpa_sem_one,
        gpa_sem_two,
        remark_sem_one,
        remark_sem_two,
      }));
    } else {
      getStudentResults();
    }
  }, []);

  const setOpenDialog = (val) => {
    setState((prevState) => ({
      ...prevState,
      isDialogOpen: val,
    }));
  };

  const onSubmit = (values) => {
    console.log(values);
  };

  return (
    <>
      {state.rows.map((row, idx) => (
        <React.Fragment key={idx}>
          <Typography
            component="h4"
            style={{
              fontSize: "18px",
              marginTop: "20px",
              marginBottom: "20px",
              color: "#000",
            }}
          >
            {row.label}
          </Typography>
          <DataTable
            columns={HEADERS}
            rows={row.entries}
            paging={false}
            searching={false}
          />
        </React.Fragment>
      ))}

      {/* Summary table */}
      <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <table
              border="1"
              cellSpacing="0"
              cellPadding="0"
              className="center"
            >
              <tbody>
                <tr align="center" valign="top">
                  <td colSpan="4">SUMMARY</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center" valign="top">
                    SEMESTER ONE
                  </td>
                  <td colSpan="2" align="center" valign="top">
                    SEMESTER TWO
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    G.P.A
                  </td>
                  <td align="center" valign="top">
                    REMARK
                  </td>
                  <td align="center" valign="top">
                    G.P.A
                  </td>
                  <td align="center" valign="top">
                    REMARK
                  </td>
                </tr>
                <tr>
                  <td align="center" valign="top">
                    {state.gpa_sem_one}
                  </td>
                  <td align="center" valign="top">
                    {state.remark_sem_one}
                  </td>
                  <td align="center" valign="top">
                    {state.gpa_sem_two}
                  </td>
                  <td align="center" valign="top">
                    {state.remark_sem_two}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* End Summary table */}
    </>
  );
};

export default StudentModuleResult;
