import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";

const StudentCard = ({ name, children }) => {
  const [today, setToday] = useState(new Date());

  const getFirstLetter = () => {
    return name[0].toUpperCase();
  };

  const getDate = () => {
    return setToday(moment(today).format("MMMM d, YYYY"));
  };

  return (
    <Card sx={{ maxWidth: 345 }} elevation={2}>
      <CardHeader
        avatar={
          <Avatar>
            {getFirstLetter()}
          </Avatar>
        }
        title={name}
        // subheader="September 14, 2016"
        subheader={moment(getDate).format("MMMM d, YYYY")}
      />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
};

export default StudentCard;
