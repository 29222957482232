import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoTwoTone";
import { Stack } from "@mui/material";
import PageContent from "components/PageContent";
import BaseButton from "helpers/BaseButton";
import useCurrentStudent from "hooks/useCurrentStudent";
import useUnitQuery from "hooks/useUnitQuery";
import { Link } from "react-router-dom";
import { getClearance } from "./service";
import TextBox from "components/TextBox";
import ClearanceOutputs from "./components/clearance-outputs";
import clearance_colors from "./components/clearance-colors"

export default function ClearanceView() {
  const me = useCurrentStudent();
  const query = useUnitQuery(() => getClearance());
  if (!me.ok) return null;
  if (!query.ok) {
    return (
      <PageContent title="Clearance Request">
        <OnEmpty />
      </PageContent>
    );
  }
  const clearance = query.data;
  return (
    <>
      <PageContent
        title="Clearance Request"
        actions={
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
            }}
          >
            <Button component="a" target="_blank" href={`api/clearances/print/${clearance.id}`}> Print</Button>
            <Typography
              style={{ fontWeight: "bold", color: clearance_colors[clearance.status] }}
            >
              {clearance.status}
            </Typography>
            <InfoIcon style={{ color: clearance_colors[clearance.status] }} />
          </Box>
        }
      >
        <Grid item container spacing={2}>
          <Grid item md={12}>
            <TextBox label="Registration" value={me.student.registrationNo} />
          </Grid>
          <Grid item md={4}>
            <TextBox label="Full Name" value={me.fullName} />
          </Grid>
          <Grid item md={4}>
            <TextBox label="Campus" value={me.campus?.campusName} />
          </Grid>
          <Grid item md={4}>
            <TextBox label="Reason" value={clearance.reason_type} />
          </Grid>
          <Grid item md={6}>
            <TextBox label="Department" value={me.department?.name} />
          </Grid>
          <Grid item md={6}>
            <TextBox label="Program" value={me.program?.name} />
          </Grid>
          <Grid item md={12}>
            <TextBox label="Short Description" value={clearance.description} />
          </Grid>
        </Grid>
        <ClearanceOutputs />
      </PageContent>
    </>
  );
}

function ClearanceProgress() {
  const items = [{}, {}, {}];
  return (
    <Box sx={{ padding: 10, marginTop: 40 }}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Typography
          variant="h5"
          style={{ marginBottom: 40, flex: 1 }}
          noWrap
          component="div"
        >
          Progress
        </Typography>
      </Box>
      {items.map((item) => (
        <Paper>
          <Stack></Stack>
        </Paper>
      ))}
    </Box>
  );
}

function OnEmpty() {
  return (
    <Grid container justifyContent="center">
      <Grid item md={12}>
        <Stack>
          <Typography paragraph>
            Before graduating or leave the institution, it's mandatory to
            complete the clearance process. This ensures that all obligations
            related to the library, finance, and other departments are settled.
            Once cleared, you will be eligible to receive your transcripts and
            certificate.
          </Typography>
          <Typography paragraph>
            Click the button below to start your clearance request. If you need
            assistance, feel free to contact the student support office.
          </Typography>
        </Stack>
      </Grid>

      <Grid item>
        <BaseButton component={Link} to="/clearance/request">
          Request Clearance
        </BaseButton>
      </Grid>
    </Grid>
  );
}
