import { DataTable, DetailTable } from "helpers/Tables";
import PageContent from "components/PageContent";
import { Button, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ButtonLink } from "helpers/BaseButton";
import { useMemo } from "react";
import usePageQuery from "hooks/usePageQuery";
import BaseTable from "helpers/BaseTable";
import { getAllocations } from "./service";

const HEADERS = [
  {
    label: "Approver",
    value: (ab) =>
      !ab.approved_by
        ? "- -"
        : `${ab.approved_by?.firstName} ${ab.approved_by?.lastName}`,
  },
  { value: "start_date", label: "Start Date" },
  { value: "end_date", label: "End Date" },
  { value: (a) => `${a.room?.building?.name}`, label: "Bulding" },
  { value: (a) => `${a.room.name}`, label: "Room" },
  { value: "status", label: "Status" },
  { value: "action", label: "Action" },
];

const makeActions = (item) => {
  return {
    ...item,
    full_name: `${item.advisor?.firstName} ${item.advisor?.lastName}`,
    action: <ButtonLink to={`/hostel/details/${item.id}`} title="View" />,
  };
};

export default function HostelAllocationList() {
  const query = usePageQuery(getAllocations, {}, makeActions);
  return (
    <PageContent
      title="Hostel Allocations"
      actions={
        <Button variant="contained" component={Link} to="/hostel/create">
          Request Allocation
        </Button>
      }
    >
      <>
        <BaseTable
          itemKey="id"
          headers={HEADERS}
          items={query.sanitizedData}
          params={query.params}
          showPagination
          onPagination={query.load}
        />
      </>
    </PageContent>
  );
}
