import React, { useEffect, useState } from "react"
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider'
import { Link } from "react-router-dom";

import { Route } from 'react-router-dom'

import Header from 'helpers/Header/Header'

import PersonalDetails from './personal-details'
import AccountDetails from './account-details'

import './profile.css'

const Home = () => {
  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="card">
          <div className="card-header">
            <Header title=" My Profile" />
          </div>
          <div className="card-body exam-card">
            <div className="block">
              <Grid container>
                <Grid item xs={2}>
                  <Paper elevation={0} style={{height: '100%', borderRight: '1px solid #ccc'}}>
                    <Tabs
                      value={selectedTab}
                      orientation="vertical"
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab component={Link} to="/profile" label="Personal Details"   />
                      <Divider />
                      <Tab component={Link} to="/profile/account-details"  label="Account Details"  />
                      <Divider />

                    </Tabs>
                  </Paper>
                </Grid>
                <Grid item xs={10} style={{ padding: '15px' }}>
                  <Route exact path="/profile" component={PersonalDetails} />
                  <Route path="/profile/account-details" component={AccountDetails} />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
