import axios from "api/axios";

const BANK_DETAILS = "/api/student-bank-account";

const getBankDetails = async (params) => {
  return await axios.get(`${BANK_DETAILS}`, { params });
};

const createBankDetails = async (params) => {
  return await axios.post(`${BANK_DETAILS}`, params);
};

const updateBankDetails = async (params) => {
  return await axios.put(`${BANK_DETAILS}`, params);
};

export { getBankDetails, createBankDetails, updateBankDetails };
