import { HIDE_SNACKBAR, SHOW_SNACKBAR } from "../action-types";

const initialState = {
  show: false,
  message: "",
  severity: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        show: true,
        message: action.payload.message,
        severity: action.payload.severity,
      };
    case HIDE_SNACKBAR:
      return { ...state, show: false, message: "", severity: "" };
    default:
      return state;
  }
};

export default reducer;
