import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import { DepartureBoard } from "@material-ui/icons";
import { Stack } from "@mui/material";
import axios from "api/axios";
import FetcherAutoComplete from "components/FetcherAutoComplete";
import PageContent from "components/PageContent";
import { Form, Formik } from "formik";
import BaseButton from "helpers/BaseButton";
import BaseSelect from "helpers/Select";
import BaseTextField from "helpers/TextField";
import useCurrentStudent from "hooks/useCurrentStudent";
import usePageQuery from "hooks/usePageQuery";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import { createClearance } from "./service";
import { useHistory } from "react-router-dom";

const INITIAL_STATE = {};
const REASON_TYPE_OPTIONS = [
  { id: "transfer", name: "Transfer" },
  { id: "completion", name: "Completion" },
  { id: "withdraw", name: "Withdraw" },
];
const APIS = {
  CAMPUS_LIST_API: "/api/lookup/campus/list",
  DEPARTMENT_LIST_API: "/api/lookup/department/all",
};
export default function ClearanceRequest() {
  const history = useHistory();
  const query = usePageQuery(() => axios.get(APIS.DEPARTMENT_LIST_API), {});
  const me = useCurrentStudent();
  if (!me.ok) return null;
  const handleSubmit = async (values) => {
    const result = await createClearance({
      description: values.description,
      reason_type: values.reason_type,
    });
    if (result.data?.status == 201) history.push("/clearance");
  };

  return (
    <PageContent title="Clearance Request">
      <Formik initialValues={INITIAL_STATE} onSubmit={handleSubmit}>
        {({ isValid, dirty }) => (
          <Form>
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item container spacing={2} md={10}>
                <Grid item md={12}>
                  <TextField
                    readOnly
                    value={me.fullName}
                    fullWidth
                    label="Registration"
                    variant="standard"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    readOnly
                    value={me.campus?.campusName}
                    fullWidth
                    label="Campus"
                    variant="standard"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    readOnly
                    value={me.student.registrationNo}
                    fullWidth
                    label="Registration"
                    variant="standard"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    readOnly
                    value={me.department?.name}
                    fullWidth
                    label="Department"
                    variant="standard"
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    readOnly
                    value={me.program?.name}
                    fullWidth
                    label="Program"
                    variant="standard"
                  />
                </Grid>
                <Grid item md={12}>
                  <BaseSelect
                    label="Select Reason"
                    name="reason_type"
                    options={REASON_TYPE_OPTIONS}
                  />
                </Grid>
                <Grid item md={12}>
                  <BaseTextField
                    fullWidth
                    label="Short Description"
                    name="description"
                    minRows={4}
                    variant="outlined"
                    multiline
                  />
                </Grid>
                <Grid item container justifyContent="center">
                  <Grid item>
                    <BaseButton type="submit" variant="contained">
                      Submit
                    </BaseButton>
                  </Grid>
                </Grid>
              </Grid>
              {query.isList && (
                <Grid item container spacing={2} md={2}>
                  <Paper
                    variant="elevation"
                    style={{
                      overflowX: "hidden",
                      padding: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Stack
                      spacing={3}
                      direction={"column"}
                      style={{ overflowX: "hidden" }}
                    >
                      <Typography component="h5">Departments</Typography>
                      {query.data.map((item) => (
                        <DepartmentItem item={item} />
                      ))}
                    </Stack>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </PageContent>
  );
}

function DepartmentItem({ item }) {
  return (
    <Paper variant="outlined" style={{ padding: 10 }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <AssignmentIndIcon color="blue" style={{ fontSize: 15 }} />
        <Typography noWrap style={{ flex: 1 }}>
          {item.name}
        </Typography>
      </Stack>
    </Paper>
  );
}
