import React from "react";
import DetailTable from "./detail-table";
import InvoiceDetailTable from "./invoice-detail-table";
import { Box, Button } from "@material-ui/core";
import { reuseBillControlNumber } from "./services";

const ViewControlNumberDetails = ({ details, receipts, onClose }) => {
  const ROWS = [
    { label: "Control Number", value: details.controlNo },
    { label: "Date Genarated", value: details.generatedDate },
    { label: "Status", value: details.status },
    { label: "Bill Number", value: details.billId },
    { label: "Bill Amount", value: `${details.billedAmount} TSH` },
  ];

  const PAID_DETAIL_HEADERS = [
    { id: 1, name: "number", label: "S/N" },
    { id: 2, name: "paymentDate", label: "DATE PAID" },
    { id: 3, name: "tiaReceiptNo", label: "RECEIPT. NO" },
    { id: 4, name: "controlNo", label: "CONTROL NO" },
    { id: 6, name: "gepgReceiptNo", label: "GePG RECEIPT" },
    { id: 5, name: "amountPaid", label: "AMOUNT PAID" },
    { id: 6, name: "currency", label: "CURRENCY" },
  ];

  const reuseCntrlNum = async () => {
    try {
      const res = await reuseBillControlNumber(details.billId);
      if (res.data.status === 200) {
        alert("Successfully, Control can now be reused")
      } else {
        alert("Something went wrong, please the system administrator")
      }
    } catch (error) {

    }

    onClose?.();
  };
  return (
    <>
      <DetailTable rows={ROWS} />
      <Box sx={{ py: 2, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={details.status != "Expired"}
          onClick={reuseCntrlNum}
        >
          Reuse Control Number
        </Button>
      </Box>
      <h5>AMOUNTS PAID USING THE CONTROL NO</h5>
      <InvoiceDetailTable headers={PAID_DETAIL_HEADERS} items={receipts} />
    </>
  );
};

export default ViewControlNumberDetails;
