import * as React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import * as actionCreators from "../../store/snackbar/index";
import { bindActionCreators } from "redux";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const SnackBar = () => {
  const state = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  const vertical = "bottom";
  const horizontal = "right";

  const payload = {
    message: "",
    severity: "",
  };

  const { hideSnackbar } = bindActionCreators(
    actionCreators,
    dispatch,
  );

  const handleClose = () => {
    hideSnackbar(payload);
  };

  return (
    <>
      {state.show && (
        <Snackbar
          open={state.show}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
        >
          <Alert
            onClose={handleClose}
            severity={state.severity}
            sx={{ width: "100%" }}
          >
            {state.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default SnackBar;
