import React, { Component } from "react";
import CollegeDisplay from "./collegeDisplay";
import params from "../../util/api_routes/params";
import api from "../../util/api";

class Diploma extends Component {
  constructor(props) {
    super(props);
    this.state = {      
      filling_arrow: "right",
      college_data: [],
      stage: parseInt(localStorage.getItem("stageid")),
      info_loading: null,
      network_error: false,
    };
  }
  componentDidMount() {
    this.getCollegeList();
  }

  getCollegeList = async () => {
    this.setState({ info_loading: true });
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("admission")),
      awardId: "3",
    };
    await api
      .post(params.GET_COLLEGE_LIST, payLoad)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              college_data: res.data.listdata,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            college_data: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };
 
  render() {
    return (
    <>
       <div >      
          <div>
          {this.state.college_data.length ? (
            <>
           <h6>College Education</h6>
           <CollegeDisplay
            college_data={
              this.state.college_data
            }
          />
          </>
          ):<>You have no Diploma records</>}    
          </div>
       </div> 
    </>
    );
  }
}

export default Diploma;