import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useField, useFormikContext } from "formik";
import { CircularProgress } from "@material-ui/core";
import axios from "api/axios";
import { parseStringTemplate } from "util/utils";

const types = ["listdata", "data", "items"];
const getResponseProperty = async (response) => {
  if(typeof response != "object") return [];
  for (let t of types) {
    if(!t) return [];
    if (t in response) {
      const found = response[t];
      return Array.isArray(found) ? found : [];
    }
  }
  return Array.isArray(response) ? response : [];
};
const FetcherAutoComplete = ({
  name,
  label,
  disabled = false,
  keyColumn = "name",
  labelTemplate = "",
  labelColumn = "name",
  defaultItems = [],
  onFetch,
  onItemChange = null,
  ...otherProps
}) => {
  const loading = false
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const [items, setItems] = useState([]);

  const handleChange = (evt, value) => {
    setFieldValue(name, value);
    if (onItemChange) {
      onItemChange(value);
    }
  };

  const configAutoComplete = {
    ...field,
    ...otherProps,
    fullWidth: true,
    onChange: handleChange,
    disabled,
  };

  const configTextField = {
    label,
    error: false,
    helperText: "",
    type: "text",
    variant: "outlined",
    ...otherProps,
    fullWidth: true,
    disabled,
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  const onSearch = async (value) => {
    if(!onFetch) return;
    const resp = await onFetch(axios, value)
    if (!resp?.data || resp.status > 300) return;
    const response = await getResponseProperty(resp.data);
    const _items = defaultItems ? [...defaultItems, ...response] : response;
    setItems(_items)
  }

  const [keyword, setKeyword] = useState("");
  useEffect(() => {
    const id = setTimeout(() => onSearch(keyword), 1000);
    return () => clearTimeout(id)
  }, [keyword])

  return (
    <Autocomplete
      {...configAutoComplete}
      getOptionSelected={(option, value) => option?.[keyColumn] === value?.[keyColumn]}
      getOptionLabel={(option) => (labelTemplate ? parseStringTemplate(labelTemplate, option) : option?.[labelColumn] ?? "")}
      options={items}
      onInputChange={(e, v) => setKeyword(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          {...configTextField}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default FetcherAutoComplete;
