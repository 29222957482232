import React, { useState,useEffect } from "react";

const INITIAL_STATE = {
   
  };

const Discount = () => {
    const [state, setState] = useState(INITIAL_STATE);

    return (
    <>
       <div className="row">
           <div className="col-sm-12">
             You have no discount
           </div>
       </div> 
    </>
  );
};

export default Discount;