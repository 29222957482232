import React, { useState,useEffect } from "react";

const INITIAL_STATE = {
   
  };

const PostEducation = () => {
    const [state, setState] = useState(INITIAL_STATE);

    return (
    <>
       <div className="row">
           <div className="col-sm-12">
              No Post Education Details
           </div>
       </div> 
    </>
  );
};

export default PostEducation;