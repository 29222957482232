import React, { Component } from "react";
import { Container, Alert } from "reactstrap";
import ApplicantProfile from "./ApplicantProfile";
import EditEmploymentDetails from "./EditEmploymentDetails";
import EmploymentDetails from "./EmploymentDetails";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";

class Employment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{ title: "Dashboard", link: "#" }],
      data: [],
      firstname: "",
      employer: "",
      address: "",
      work_station: "",

      employer_detail: "",
      address_detail: "",
      work_station_detail: "",

      details: [],
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  componentDidMount() {
    this.getEmploymentDetails();
    console.log(parseInt(localStorage.getItem("stageid")));
  }

  getEmploymentDetails = async () => {
    let payLoad = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
    };
    try {
      await api
        .post(params.GET_EMPLOYMENT_DETAILS, payLoad)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.stsCode === "0000") {
              this.setState({
                details: res.data.data,
              });
              // window.location.reload();
              console.log(this.state.details[0].employerName);
              // console.log(parseInt(localStorage.getItem("stageid")));
            } else {
              this.setState({
                employerName: " ",
                employerAddress: " ",
                workStation: "",
              });
              this.setState({ credentialError: res.data.stsMessage });
            }
          }
        })
        .catch((err) => {});
    } catch (error) {}
  };
  render() {
    // const { employee } = this.state;
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.stage < 12 ? (
              <>
                {" "}
                {/* <div className="page-content"> */}
                <div className="card">
                  <div className="card-header">
                    <div className="float-left card-header-title">
                      {/* Completed */}
                    </div>
                    <div className="float-right card-header-title">
                      Application Number : {localStorage.getItem("id")}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="block">
                      <div className="alert alert-warning">
                        {/* <Alert severity="warning"> */}
                        Please complete the previous stage by clicking "Next"
                        button
                        {/* {console.log(this.state.stage)} */}
                        {/* </Alert> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </>
            ) : (
              <>
                {this.state.details.length > 0 ? (
                  <EditEmploymentDetails
                    employer_detail={this.state.details[0].employerName}
                    address_detail={this.state.details[0].employerAddress}
                    work_station_detail={this.state.details[0].workStation}
                  />
                ) : (
                  <EmploymentDetails />
                )}
              </>
            )}
          </Container>
          {/* {console.log(this.state.data[0].firstname)} */}
        </div>
      </React.Fragment>
    );
  }
}

export default Employment;
