import React, { Component } from "react";
import { Button, Alert } from "reactstrap";
import { Container } from "reactstrap";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";
import PerformDeclaration from "./PerformDeclaration";
import DeclarationInstruction from "./DeclarationInstruction";

class Declaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }

  render() {
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <Container fluid>
          {this.state.stage < 15 ? (
            <>
              {" "}
              <div className="page-content">
                <div className="card">
                  <div className="card-header">
                    <div className="float-left card-header-title">
                      {/* Completed */}
                    </div>
                    <div className="float-right card-header-title">
                      Application Number : {localStorage.getItem("id")}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="block">
                      <div className="alert alert-warning">
                        {/* <Alert severity="warning"> */}
                        Please complete the previous stage by clicking "Next"
                        button
                        {/* {console.log(this.state.stage)} */}
                        {/* </Alert> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="page-content">
              <div className="card">
                <div className="card-header">
                  <div className="float-left card-header-title">
                    Declaration
                  </div>
                  <div className="float-right card-header-title">
                    Application Number : {localStorage.getItem("id")}
                  </div>
                </div>
                <div className="card-body">
                  <div className="block">
                    {/* {this.state.stage < 8 ? ( */}
                    <>
                      {" "}
                      <div className="row">
                        <div className="col-sm-12">
                          {/* <Alert severity="warning">
                          To declare and submit your application. Please fill
                          all the information in the previous stages
                        </Alert> */}
                        </div>
                      </div>
                    </>
                    {/* ) : ( */}
                    <>
                      {/* {this.state.stage === 8 ? ( */}
                      <>
                        <div className="row">
                          <div className="col-sm-1">{/*Display steps*/}</div>
                          <div className="col-sm-5">
                            <DeclarationInstruction
                            // status={this.state.filling_arrow}
                            />
                          </div>
                          <div className="col-sm-6">
                            <PerformDeclaration />
                          </div>
                        </div>

                        {/* //-----------dermacation-------// */}
                      </>
                      {/* ) : ( */}
                      <>
                        {/* {this.state.stage > 8 ? ( */}
                        <> {/* <Submission /> */}</>
                        {/* ) : ( */}
                        <></>
                        {/* )}{" "} */}
                        {/* <div className="row">
                            <div className="col-sm-12">
                              <Alert severity="success">
                                <strong>Success!</strong> You have successfully
                                submitted your application. Wait for final
                                selection
                              </Alert>
                            </div>
                          </div> */}
                        {/* //-----------dermacation-------// */}
                      </>
                      {/* )} */}
                    </>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>
      </React.Fragment>
    );
  }
}

export default Declaration;
