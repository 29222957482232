import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 10,
    paddingRight: 0,
    paddingLeft: 5,
    paddingBottom: 10,
  },
}));

export const PAGINATION_PARAMS = {
  size: null,
  total: null,
  page: null,
  searchType: "or",
};

const Pagination = (props) => {
  const { rows, params, onPagination } = props;
  const [val, setVal] = useState(10);

  const classes = useStyles();

  /**
   * gets selected event's value from the UI and set it as the current
   * value of the page
   * @param event: React.Event
   * return void
   */
  const handleChange = (event) => {
    setVal(event.target.value);
    onPagination({ size: event.target.value, page: params.page });
  };

  /**
   * checks if previious button is disabled
   * @return boolean
   */
  const isPrevBtnDisabled = () => {
    return params.page === 0 ? true : false;
  };

  /**
   * checks if next button is disabled
   * @return boolean
   */
  const isNextBtnDisabled = () => {
    return params.size * (params.page + 1) >= params.total;
  };

  /**
   * navigates to the next page by incrementing the current page by 1
   * @return void
   */
  const goToNextPage = () => {
    const currentPage = params.page + 1;
    let payload = {
      size: val,
      page: currentPage,
    };
    onPagination(payload);
  };

  /**
   * navigates to the previious page by decrementing the current page by 1
   * @return void
   */
  const goToPreviousPage = () => {
    let previousPage = params.page - 1;
    let payload = {
      size: val,
      page: previousPage,
    };
    onPagination(payload);
  };

  return (
    <>
      <Grid
        container
        className={classes.root}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box mr={2}>Per Page</Box>{" "}
        <Box mr={2}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={val}
            label="Page"
            onChange={(event) => handleChange(event)}
          >
            {rows.map((row) => (
              <MenuItem key={row} value={row}>
                {row}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          {params.page + 1}-{params.size} of {params.total}
        </Box>
        <Box>
          <IconButton disabled={isPrevBtnDisabled()} onClick={goToPreviousPage}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton disabled={isNextBtnDisabled()} onClick={goToNextPage}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      </Grid>
    </>
  );
};

export default Pagination;
