/**
  * using this component
  * import * as Yup from 'yup'
  * import DateTimePicker from 'helpers/DateTimePicker'
  *

  * define form attribute
  * const startDate = '',

  * validation
  * const FORM_VALIDATION = Yup.object().shape({
    startDate: Yup.date().required('Start Date is required'),
   })

  * <DateTimePicker name='startDate' label='Select Arrival Date' />
*/
import React, { useState } from "react";

import { useFormikContext, useField } from "formik";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  DateTimePicker,
} from "@material-ui/pickers";

import DateFnsAdapter from "@date-io/date-fns";
import { TextField } from "@material-ui/core";

 const dateFns = new DateFnsAdapter();
const DateTimePickerField = ({
  name,
  format = "DD/MM/yyyy HH:mm:ss",
  outputFormat = null,
  ...otherProps
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  //const [value, setValue] = useState(null);

  const handleChange = (value) => {
    if (!value) {
      setFieldValue(name, "");
      return;
    }
    const val2 = dateFns.format(
      value,
      outputFormat ?? format ?? "dd/MM/yyyy HH:mm:ss",
    );
    setFieldValue(name, val2);
  };

  const configDatePicker = {
    name,
    ...field,
    ...otherProps,
    value: !field.value ? null : field.value,
    inputVariant: "outlined",
    disableToolbar: true,
    defaultValue: "",
    fullWidth: true,
    autoOk: true,
    onChange: handleChange,
    clearable: true,
    error: false,
    helperText: null,
  };

  if (meta && meta.touched && meta.error) {
    configDatePicker.error = true;
    configDatePicker.helperText = meta.error;
    configDatePicker.inputProps = {
      clearable: true,
      error: true,
      helperText:  meta.error,
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsAdapter}>
      <DateTimePicker
        {...configDatePicker}
        format={format ?? "dd-MM-yyyy HH:mm:ss"}
        renderInput={(params) => {
          return (
            <TextField
              fullWidth={configDatePicker.fullWidth}
              {...params}
              name={name}
            />
          );
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimePickerField;
