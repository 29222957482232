import Home from "../home";

const profileRoutes = [
  {
    path: "/profile",
    exact: true,
    component: Home,
  },
];

export default profileRoutes;