import React, { Component } from "react";
import { Button } from "reactstrap";
// action
import {
  registerUser,
  registerUserFailed,
  apiError,
} from "../../store/actions";
import localStorageSetup_Register from "../../util/setupLocalStorage_Register";
import localStorageSetup from "../../util/setupLocalStorage";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

// Redux
import { connect } from "react-redux";
import { FaRegRegistered } from "react-icons/fa";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import { withRouter, Link, Redirect } from "react-router-dom";
import axios from "axios";

import {
  authenticateUser,
  getUserDetails,
} from "./services/authentication-service";

// import images

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      surname: "",
      program: [],
      campus: "",
      admno: "",
      campus: [],
      campusId: "",
      errors: {},
      programId: "",
      fields: {},
      redirect: false,
      campus_loading: false,
      program_loading_pg: false,
      stage: parseInt(localStorage.getItem("stageid")),
      stageid: 1,
      credentialError: "",
      user_data: [],
      hide: true,
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  hideSwitch = (ev) => {
    this.setState({ hide: !this.state.hide });
  };

  validateForm = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["username"] || typeof fields["username"] === undefined) {
      formIsValid = false;
      errors["username"] = "* Wrong email or password.";
    }
    if (!fields["password"] || typeof fields["password"] === undefined) {
      formIsValid = false;
      errors["username"] = "* Wrong email or password.";
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  // componentDidMount() {
  //   this.getCampus();
  //   this.getProgram();
  // }

  // getCampus = async () => {
  //   this.setState({ campus_loading: true });
  //   await api
  //     .get(params.CAMPUS_LIST)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         //console.log(res.data.listdata);
  //         if (res.data.stsCode === "0000") {
  //           this.setState({
  //             campus: res.data.listdata,
  //             campus_loading: false,
  //             info_loading: false,
  //             network_error: false,
  //           });
  //           // console.log(this.state.campus);
  //         }
  //       } else {
  //         this.setState({
  //           campus: [],
  //           campus_loading: false,
  //           network_error: true,
  //           program_loading: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         info_loading: false,
  //         network_error: true,
  //         program_loading: false,
  //       });
  //     });
  // };

  // getProgram = async () => {
  //   this.setState({ program_loading_pg: true });
  //   await api
  //     .get(params.ALL_PROGRAMMES)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         // console.log(res.data.data);

  //         if (res.data.stsCode === "0000") {
  //           this.setState({
  //             program: res.data.data,
  //             program_loading_pg: false,
  //             info_loading: false,
  //             network_error: false,
  //           });
  //         }
  //       } else {
  //         this.setState({
  //           program: [],
  //           program_loading_pg: false,
  //           network_error: true,
  //           program_loading: false,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         info_loading: false,
  //         network_error: true,
  //         program_loading: false,
  //       });
  //     });
  // };

  RegisterStudent = async (e) => {
    e.preventDefault();

    //check the information if exists
    this.setState({ info_loading: true });
    try {
      let user = null;
      // if (this.validateForm()) {
      const response = await authenticateUser({
        username: this.state.username,
        password: this.state.password,
      });
      // }
      if (response.status === 200) {
        user = await getUserDetails(response.data.access_token);
      }
      if (user.status === 200) {
        console.log(user);
        let status = await localStorageSetup_Register(user.data.data);
        const TOKEN = JSON.parse(localStorage.getItem("token"));
        console.log(TOKEN);
        this.getApplicantProfile(); //In order to get the admno to pass it to next auth api
        this.setState({ admno: this.state.user_data.applicationProfile.id });
        console.log(this.state.admno);
        this.authenticate();
      }
    } catch (error) {
      this.setState({ username: "", password: "" });
      this.setState({ credentialError: "Wrong Username or Password" });
      localStorage.clear();
    }
  };

  getApplicantProfile = () => {
    this.setState({
      user_data: JSON.parse(localStorage.getItem("authenticatedUser")),
    });
  };

  authenticate = async (e) => {
    // e.preventDefault();
    //check the information if exists
    this.setState({ info_loading: true });

    if (this.validateForm()) {
      let payLoad = {
        admno: this.state.admno,
        surname: "test",
        campusId: "test",
        programId: "test",
      };
      const token = JSON.parse(localStorage.getItem("token"));
      console.log(token);
      try {
        await axios
          .post(params.AUTHENTICATE_REGISTRATION, payLoad, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status === 200) {
              if (res.data.stsCode === "0000") {
                // console.log(res.data);

                const status = localStorageSetup(res.data, 1);
                console.log(status);
                if (status === true) {
                  this.setState({
                    redirect: true,
                  });
                  window.location.reload();
                }
              } else {
                this.setState({
                  admno: " ",
                  surname: " ",
                  campusId: "",
                  programId: "",
                });
                this.setState({ credentialError: res.data.stsMessage });
                localStorage.clear();
              }
            }
          })
          .catch((err) => {});
      } catch (error) {}
    }
  };

  render() {
    const list_style = {
      padding: "10px",
    };

    const { hide } = this.state;

    return (
      <React.Fragment>
        <div>
          <div className="row">
            <br />
          </div>

          <div className="col-sm-12">
            <div className="card">
              {/* <div className="card-header"></div> */}
              <div className="card-block">
                <div className="row">
                  {/** ------------  Login Form ---------- **/}
                  <div className="col-lg-12 col-xl-8">
                    <div className="block login">
                      <p className="m-0 paragaraph-justification">
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "20px",
                            // padding: "250px",
                          }}
                        >
                          {" "}
                          Welcome to TIA Student Online Registration System
                        </span>
                      </p>
                      <br></br>
                      <p className="m-0">
                        <b>
                          This is secured system for registering new TIA
                          students in various programmes. In order to register ,
                          you are required to provide your Username and Password
                          you used during online application.
                        </b>
                      </p>
                    </div>
                  </div>

                  {/* End of Login Form */}

                  <div className="col-lg-12 col-xl-4">
                    <div className="block login">
                      {this.state.redirect && (
                        <Redirect to="/registration-feepayment" />
                      )}
                      <h7>
                        Use credentials you used during online application
                      </h7>
                      {this.state.credentialError ? (
                        <div className="errorMsg">
                          {this.state.credentialError}{" "}
                          <i className="ti-hand-point-down"></i>
                        </div>
                      ) : null}
                      <form onSubmit={this.RegisterStudent}>
                        <div className="form-group">
                          <label>Username </label>
                          <input
                            name="username"
                            className={
                              this.state.errors.username
                                ? "input-group-danger form-control"
                                : "form-control"
                            }
                            type="text"
                            value={this.state.username}
                            onChange={this.handleInputState}
                            autoComplete="off"
                            placeholder="Email"
                          />
                        </div>

                        <div className="form-group">
                          <label>Password </label>
                          <input
                            // type="password"
                            type={hide ? "password" : "text"}
                            name="password"
                            value={this.state.password}
                            onChange={this.handleInputState}
                            className="form-control"
                            placeholder="Password"
                          />
                          <span
                            className="password__show"
                            onClick={this.hideSwitch}
                            style={{
                              position: "absolute",
                              right: 20,
                            }}
                          >
                            {hide ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>

                        <div className="form-group">
                          <Button
                            type="submit"
                            variant="primary"
                            className="btn btn-round  btn-sm"
                          >
                            <FaRegRegistered />
                            {"     "} Register
                          </Button>
                          <Link to={"/login"}>
                            {" "}
                            If you are already registered, Click{" "}
                            <span style={{ color: "green" }}>Here</span> to go
                            to Login Page
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
