import { Route } from "react-router-dom";
import ViewHostelAllocation from "./view";
import HostelAllocationCreate from "./forms/create";
import HostelRequestList from "./allocations";

function Paths() {
  return (
    <>
      <Route path="/hostel" exact component={HostelRequestList} />
      <Route path="/hostel/details/:id" exact component={ViewHostelAllocation} />
      <Route path="/hostel/create" exact component={HostelAllocationCreate} />
    </>
  );
}
const routes = [
  {
    path: "/hostel",
    exact: true,
    component: Paths,
  },
  {
    path: "/hostel/create",
    exact: true,
    component: HostelAllocationCreate,
  },
  {
    path: "/hostel/details/:id",
    exact: true,
    component: ViewHostelAllocation,
  },
];

export default routes;
