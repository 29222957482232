import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import { nhifCardApplication } from "./services";

import BaseTextField from "helpers/TextField";
import Grid from "@material-ui/core/Grid";
import DisplayNhifControlno from "./display-nhif-controlno";
import BaseDialog from "helpers/BaseDialog";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "helpers/ConfirmDialog";
import changeStage from "../../../util/changeStage";

const FORM_INITIAL_STATE = {
  nhif_no: "",
  display_controlno: false,
  openConfirmDialog: false,
  description: "",
};

const ApplyNhifCard = () => {
  const [state, setState] = useState(FORM_INITIAL_STATE);
  let history = useHistory();

  const closeDialog = () => {
    return setState((prevState) => ({
      ...prevState,
      display_controlno: false,
    }));
  };

  const openConfirmDialog = (item) => {
    setState((prevState) => ({
      ...prevState,
      openConfirmDialog: true,
    }));
  };

  const closeConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      openConfirmDialog: false,
    }));
  };

  const handleSearch = (helpers, values) => {
    let payLoad = {
      admno: localStorage.getItem("id"),
      // admno: 202200009,
    };

    // return
    //the following 4 lines should be commented later i.e display_controlno:true
    // setState((prevState) => ({
    //   ...prevState,
    //   display_controlno: true,
    // }));

    nhifCardApplication(payLoad).then((res) => {
      console.log(res);
      if (res.data.statusCode === 200) {
        setState((prevState) => ({
          ...prevState,
          display_controlno: true,
        }));
      } else {
        // return;
        setState((prevState) => ({
          ...prevState,
          description: res.data.message,
        }));
        openConfirmDialog();
      }
    });
  };

  const moveNextStageCheck = async (helpers, values) => {
    let payLoadStage = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 15,
    };

    if (parseInt(localStorage.getItem("stageid")) === 14) {
      let result = await changeStage(payLoadStage);
      if (result === 1) {
        setState({ loading: false, network_error: false });
        history.push("/registration-declaration");
      } else {
      }
    } else {
      history.push("/registration-declaration");
    }
  };


  return (
    <>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <h6>Click the button below to apply for NHIF Card</h6>

          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(helpers, values) => handleSearch(values, helpers)}
          >
            {({ isValid, dirty }) => (
              <Form className="form-inline">
                <button
                  style={{ marginTop: "20px" }}
                  className="btn btn-round  btn-md btn-primary form-inline-button"
                  type="submit"
                  name="submit"
                >
                  APPLY NHIF CARD
                </button>
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={FORM_INITIAL_STATE}
            onSubmit={(helpers, values) => moveNextStageCheck(values, helpers)}
          >
            {({ isValid, dirty }) => (
              <Form className="form-inline">
                <>
                  {/* <button
                    style={{ marginTop: "20px" }}
                    className="btn btn-round  btn-md btn-primary form-inline-button"
                    type="submit"
                    name="submit"
                  >
                    Skip this step
                  </button> */}
                </>
                <br />
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <BaseDialog
        fullWidth
        maxWidth={"md"}
        openDialog={state.display_controlno}
        setOpenDialog={() => closeDialog()}
        title="VIEW CONTROL NUMBER AND PAYMENT STATUS"
      >
        <DisplayNhifControlno />
      </BaseDialog>

      <ConfirmDialog
        open={state.openConfirmDialog}
        title="Registration Status"
        text={state.description}
      >
        <Button autoFocus onClick={closeConfirmDialog} color="primary">
          Cancel
        </Button>
      </ConfirmDialog>
    </>
  );
};

export default ApplyNhifCard;
