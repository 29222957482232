import React, { Component } from "react";
import hashPasscode from "../../util/hashPasscode";
import api from "../../util/api";
import params from "../../util/api_routes/params";
import { changePassword } from "./services";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../store/actions";


class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldpassword: "",
      newpassword: "",
      fields: {},
      errors: {},
      pass: "",
      hide: true,
    };
  }

  handleInputState = (e) => {
    let fields = this.state.fields;
    this.setState({ [e.target.name]: e.target.value });
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  };

  hideSwitch = (ev) => {
    this.setState({ hide: !this.state.hide });
  };


  submissionValidation = () => {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    var specialChar = /[^A-Za-z0-9]/g;


    // if (!fields["oldpassword"] || typeof fields["oldpassword"] === undefined) {
    //   formIsValid = false;
    //   errors["oldpassword"] = "* Please fill the old password.";
    // }
    if (!fields["newpassword"] || typeof fields["newpassword"] === undefined) {
      formIsValid = false;
      errors["newpassword"] = "* Please fill the new password.";
    } else {
      if (!lowerCase.test(fields["newpassword"])) {
        formIsValid = false;
        errors["newpassword"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!upperCase.test(fields["newpassword"])) {
        formIsValid = false;
        errors["newpassword"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!numbers.test(fields["newpassword"])) {
        formIsValid = false;
        errors["newpassword"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (!specialChar.test(fields["newpassword"])) {
        formIsValid = false;
        errors["newpassword"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      } else if (fields["newpassword"].length < 8) {
        formIsValid = false;
        errors["newpassword"] =
          "* Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special.";
      }
    }
    if (!fields["pass"] || typeof fields["pass"] === undefined) {
      formIsValid = false;
      errors["pass"] = "* Confirm the password.";
    }
    if (fields["newpassword"] !== fields["pass"]) {
      formIsValid = false;
      errors["pass"] = "* Passwords does not match.";
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  changePassword = async (e) => {
    e.preventDefault();
    if (this.submissionValidation()) {
      const payload = {
        // userId: auth.user.id,
        userId: localStorage.getItem("userId"),
        newPassword: this.state.newpassword,
        newPasswordConfirmation: this.state.pass,
      };

      // const response = await changePassword(payload);
      // if (response.status === 201 || response.status === 200) {
      //   alert("Passsword changed successfully")
      // } else {
      //   console.log(response)
      // }

      await api
        .post(params.CHANGE_PASSWORD, payload)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            alert("Passsword changed successfully");
            localStorage.clear();
            this.props.logoutUser(this.props.history);
            window.location.reload();
          } else {
            alert("Failed to change password")
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    }

  };

  render() {
    const { hide } = this.state;
    return (
      <>
        <form onSubmit={this.changePassword}>
          <fieldset className="scheduler-border">
            <legend className="scheduler-border">
              Change Password
            </legend>

            <div className="form-group">
              <label>
                {" "}
                Username :
              </label>
              {(localStorage.getItem("userName"))}
            </div>
            {/* <div className="form-group">
            <label> Old Password * </label>
            <input
              name="oldpassword"
              type="password"
              value={this.state.oldpassword}
              onChange={this.handleInputState}
              className="form-control"
              placeholder="Password"
            />
            {this.state.errors.oldpassword ? (
              <div className="errorMsg">
                {this.state.errors.oldpassword}
              </div>
            ) : null}
          </div> */}
            <div className="form-group">
              <label>New Password * </label>
              <input
                name="newpassword"
                // type="password"
                type={hide ? "password" : "text"}
                value={this.state.newpassword}
                onChange={this.handleInputState}
                className="form-control"
                placeholder="Password"
              />
              <span
                className="password__show"
                onClick={this.hideSwitch}
                style={{
                  position: "absolute",
                  right: 50,
                }}
              >
                {hide ? <FaEye /> : <FaEyeSlash />}
              </span>
              {this.state.errors.newpassword ? (
                <div className="errorMsg">
                  {this.state.errors.newpassword}
                </div>
              ) : null}
            </div>

            <div className="form-group">
              <label> Confirm Password * </label>
              <input
                placeholder="Confirm Password"
                className="form-control"
                name="pass"
                // type="password"
                type={hide ? "password" : "text"}
                value={this.state.pass}
                onChange={this.handleInputState}
              />
              <span
                className="password__show"
                onClick={this.hideSwitch}
                style={{
                  position: "absolute",
                  right: 50,
                }}
              >
                {hide ? <FaEye /> : <FaEyeSlash />}
              </span>
              {this.state.errors.pass ? (
                <div className="errorMsg">
                  {this.state.errors.pass}
                </div>
              ) : null}
            </div>

            <div className="row">
              <div className="col-sm-5"></div>
              <div className="col-sm-2 ">
                <button className="btn btn-round  btn-sm btn-primary verification-button">
                  Save{" "}
                  <i className="ti-arrow-circle-right"></i>
                </button>
              </div>
              <div className="col-sm-2"></div>
            </div>
          </fieldset>
        </form>
      </>
    );
  }
}

export default withRouter(connect(null, { logoutUser })(AccountDetails));
// export default withRouter(connect(null, { logoutUser })(Logout));
