import React, { useMemo, useCallback } from "react";

const parseUrlParams = (opts) => {
  let next_params = { ...opts?.defaultParams };
  if (opts?.useSearchParams === false) return next_params;
  if (typeof window != "undefined") {
    const url = new URL(window.location.href);
    url.searchParams.forEach((v, k) => (next_params[k] = v));
  }
  return next_params;
};
const writeUrlParams = (next_params, opts) => {
  if (typeof window == "undefined") return;
  if (opts?.useSearchParams === false) return;
  const url = new URL(window.location.href);
  Object.entries(next_params).forEach(([key, value]) =>
    url.searchParams.set(key, value)
  );
  window.history.replaceState({}, "", url.toString());
};
export default function usePageQuery(action, opts, sanitizer, deps) {
  const ref = React.useRef({});
  const preParams = useMemo(() => parseUrlParams(opts), []);
  const [params, setParams] = React.useState(preParams);
  const [data, setData] = React.useState(opts?.defaultValue ?? []);
  ref.current.action = action;
  ref.current.next_params = params;
  ref.current.sanitizer = sanitizer;
  ref.current.opts = opts;
  ref.current.deps = deps;
  const afterLoad = React.useCallback(
    (res) => {
      if (!res || !res.data || !ref.current.action) {
        setData(ref.current.opts?.defaultValue);
        return;
      }
      const result = res.data;
      const { page, size, total } = result;
      setData(result.data || ref.current.opts?.defaultValue);
      setParams((p) => ({ ...p, page, size, total }));
    },
    [setData, setParams]
  );
  const load = React.useCallback(
    ($params) => {
      const next_params = { ...ref.current.next_params, ...$params };
      ref.current.next_params = next_params;
      setParams((p) => ({ ...p, ...next_params }));
      if (!ref.current.action)
        return Promise.resolve(ref.current.opts?.defaultValue);
      writeUrlParams(next_params, ref.current.opts);
      if (
        Array.isArray(ref.current.deps) &&
        ref.current.deps.filter((d) => ref.current.next_params[d] == undefined)
          .length
      ) {
        return Promise.resolve(ref.current.opts?.defaultValue);
      }
      return Promise.resolve(ref.current.action(ref.current.next_params)).then(
        afterLoad
      );
    },
    [setParams, ref]
  );
  React.useEffect(() => {
    if (opts?.prefech !== false) load();
  }, [opts?.prefech]);

  const sanitizedData = React.useMemo(() => {
    return data
      ?.map((item) =>
        ref.current.sanitizer ? ref.current.sanitizer(item) : item
      )
      .map((item) => {
        if (!opts?.sanitize?.length) return item;
        return opts.sanitize.reduce((item, c) => {
          if (typeof item[c] == "boolean") item[`$${c}`] = item[c].toString();
          else if (item[c] == null) item[`$${c}`] = "Empty";
          else item[`$${c}`] = item[c];
          return item;
        }, item);
      });
  }, [data, opts?.sanitize?.length]);
  const debouncedLoad = useCallback(
    ($params) => {
      clearTimeout(ref.current._debounced);
      ref.current._debounced = setTimeout(() => load($params), 1000);
    },
    [load, ref]
  );
  return { isList: Array.isArray(data), data, sanitizedData, params, setParams, load, debouncedLoad };
}
