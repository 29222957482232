import React, { useState, useEffect } from "react";
import {
  getInvoiceDetails,
  printStudentInvoice,
} from "../fee-payment/services";
import { toCurrency, toDate } from "helpers/utils";
import ViewDebitedDetails from "./view-debited-details";
import BaseDialog from "helpers/BaseDialog";

const INITIAL_STATE = {
  admno: null,
  invoideDetails: null,
  printInvoice: null,
  isPrintInvoiceDialogOpen: false,
};

const DebitAmount = () => {
  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    debitDetails();
  }, []);

  const debitDetails = async () => {
    const res = await getInvoiceDetails({
      admno: localStorage.getItem("admission"),
    });
    if (res.data && res.data.stsCode === "0000") {
      setState((prevState) => ({
        ...prevState,
        invoideDetails: generateInvoiceDetailsData(res.data.data),
      }));
    }
  };

  const closeDialog = (type) => {
    return setState((prevState) => ({
      ...prevState,
      isPrintInvoiceDialogOpen: false,
    }));
  };

  const openLink = async (e, invoiceNo) => {
    e.preventDefault();
    const res = await printStudentInvoice(invoiceNo);
    if (res.data.stsCode === "0000") {
      setState((prevState) => ({
        ...prevState,
        printInvoice: res.data.data,
        isPrintInvoiceDialogOpen: true,
      }));
    }
  };

  const createInvLink = (invoiceNo) => (
    <a href="#" onClick={(e) => openLink(e, invoiceNo)}>
      {invoiceNo}
    </a>
  );

  const generateInvoiceDetailsData = (data) => {
    return data.map((item, idx) => ({
      ...item,
      number: idx + 1,
      amount: toCurrency(item.amount),
      controlNo: item.controlNo,
      invoiceNo: item.invoiceNo,
      // invoiceNo: createInvLink(item.invoiceNo),
      createdDate: toDate(item.createdDate),
      cur: "TZS",
    }));
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          {state.invoideDetails !== null ? (
            <ViewDebitedDetails items={state.invoideDetails} />
          ) : null}
        </div>
      </div>

      <BaseDialog
        fullWidth
        maxWidth={"md"}
        openDialog={state.isPrintInvoiceDialogOpen}
        setOpenDialog={() => closeDialog()}
        title="PRINT INVOICE"
      >
        <embed
          width={925}
          height={925}
          src={`data:application/pdf;base64,${state.printInvoice}`}
        />
      </BaseDialog>
    </>
  );
};

export default DebitAmount;

