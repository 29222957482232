import React from "react";

const Header = ({ title, children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="float-left card-header-title">{title}</div>
      <div className="float-right card-header-title">{children}</div>
    </div>
  );
};

export default Header;
