import React, { Component } from "react";
import { Container, Alert } from "reactstrap";
import EditPersonalDetails from "./EditPersonalDetails";
import api from "../../util/api";
import params from "../../util/api_routes/params";

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{ title: "Dashboard", link: "#" }],
      data: [],
      next_of_kin_data: [],
      firstname: "",
    };
  }
  componentDidMount() {
    this.getApplicantProfile();
    this.getNextofKinInformation();
  }
  getApplicantProfile = () => {
    this.setState({ data: JSON.parse(localStorage.getItem("authUser")) });
  };

  getNextofKinInformation = async () => {
    this.setState({
      info_loading: true,
    });
    await api
      .get(params.NEXT_OF_KIN_LIST + localStorage.getItem("admission"))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              next_of_kin_data: res.data.data,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
            // console.log(this.state.next_0f_kin_data[0].fullname);
          }
        } else {
          this.setState({
            next_of_kin_data: [],
            info_loading: false,
            network_error: true,
            filling_arrow: "right",
          });
        }
      })
      .catch((error) => {
        this.setState({ network_error: true, info_loading: false });
      });
  };

  render() {
    // console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        {/* <div className="page-content"> */}
          <Container fluid>       
              <>
                {this.state.data !== null ? (
                  <EditPersonalDetails
                    data={this.state.data}
                    kindata={this.state.next_of_kin_data}
                  />
                ) : (
                  <PersonalDetails />
                )}
              </>      
          </Container>
          {/* {console.log(this.state.data[0].firstname)} */}
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default PersonalDetails;
