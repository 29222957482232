import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import BaseSelect from "helpers/Select";
import BaseDialog from "helpers/BaseDialog";
// import { searchCard } from "./services";
import { verifyCard } from "./services";
import Fetcher from "helpers/Fetcher";
import ConfirmDialog from "helpers/ConfirmDialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import BaseTextField from "helpers/TextField";
import TextField from "helpers/TextField";
import SubmitDetailsToNhif from "./submit-details-to-nhif";
import RenewNhifCard from "./renew-nhif-card";
import changeStage from "../../../util/changeStage";

const APIS = {
  ACADEMIC_YEARS_API: "/api/academic_year/all",
  PROGRAMMES: "/api/program/all",
  CAMPUSES: "/api/lookup/campus/list",
  INVOICE_TYPE: "/api/finance/invoice_type/all",
  SELECTION_ROUND: "/api/round/all",
};

const question_one = [
  { name: "yes", id: "1" },
  { name: "no", id: "2" },
];

const question_two = [
  { name: "NHIF", id: "1" },
  { name: "Others", id: "2" },
];

const INITIAL_STATE = {
  program: "",
  campus: "",
  ac_yr: "",
  round: "",
  type: "",
  openConfirmDialog: false,
  description: "",
  submitDetailsToNhif: false,
  openFormTwo: false,
  openFormThree: false,
  cardNo: "",
  isStudentCard: "",
  statusCode: "",
};

const FORM_VALIDATION = Yup.object().shape({
  answer_one: Yup.string().required("Please answer the given question"),
  // answer_two: Yup.string().required("Please answer the given question"),
});

const ResponseForm = () => {
  const [state, setState] = useState(INITIAL_STATE);
  let history = useHistory();
  // useEffect(() => {
  //   console.log(state.viewReport)
  // }, [state.regNo]);

  const closeDialog = () => {
    return setState((prevState) => ({
      ...prevState,
      submitDetailsToNhif: false,
    }));
  };

  const openConfirmDialog = (item) => {
    setState((prevState) => ({
      ...prevState,
      openConfirmDialog: true,
    }));
  };

  const closeConfirmDialog = () => {
    setState((prevState) => ({
      ...prevState,
      openConfirmDialog: false,
    }));
  };

  const handleSearch = async (helpers, values) => {
    if (values.answer_one === "1") {
      setState((prevState) => ({
        ...prevState,
        openFormTwo: true,
      }));
    } else if (values.answer_one === "2") {
      setState((prevState) => ({
        ...prevState,
        openFormTwo: false,
        openFormThree: false,
        submitDetailsToNhif: true,
      }));
    }
    if (values.answer_two === "1") {
      setState((prevState) => ({
        ...prevState,
        openFormThree: true,
      }));
      console.log(values.nhif_card);
      if (
        values.nhif_card != undefined &&
        values.nhif_card != "" &&
        values.nhif_card != null
      ) {
        let payLoad = {
          cardNo: values.nhif_card,
          admno: parseInt(localStorage.getItem("id")),
        };

        // setState((prevState) => ({
        //   ...prevState,
        //   description: "The Card is a Student Card",
        //   cardNo: values.nhif_card,
        // }));

        verifyCard(payLoad).then((res) => {
          console.log(res);
          if (res.data.data.statusCode === 200) {
            // if (res.data.code === "200") {
            // } else {
            // console.log(res.data);
            setState((prevState) => ({
              ...prevState,
              description: res.data.data.message,
              cardNo: values.nhif_card,
              statusCode: res.data.data.statusCode,
            }));
            openConfirmDialog();
            //   }
            // } else {
          } else if (res.data.data.statusCode === 406) {
            setState((prevState) => ({
              ...prevState,
              cardNo: values.nhif_card,
              description: res.data.data.message,
              isStudentCard: res.data.data.isStudentCard,
              statusCode: res.data.data.statusCode,
            }));
            openConfirmDialog();
          } else {
            setState((prevState) => ({
              ...prevState,
              cardNo: values.nhif_card,
              description: res.data.data.message,
              isStudentCard: res.data.data.isStudentCard,
              statusCode: res.data.data.statusCode,
            }));
            openConfirmDialog();
          }
        });
      }
    } else if (values.answer_two === "2") {
      alert("Please contact warden office for verification of your card");
    }
  };

  const moveNextStageCheck = async (helpers, values) => {
    let payLoadStage = {
      applicationProfileId: parseInt(localStorage.getItem("id")),
      stageId: 15,
    };

    if (parseInt(localStorage.getItem("stageid")) === 14) {
      let result = await changeStage(payLoadStage);
      if (result === 1) {
        setState({ loading: false, network_error: false });
        history.push("/registration-declaration");
      } else {
      }
    } else {
      history.push("/registration-declaration");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <br />
          <Formik
            initialValues={INITIAL_STATE}
            validationSchema={FORM_VALIDATION}
            onSubmit={(helpers, values, event) => handleSearch(values, helpers)}
          >
            {({ isValid, dirty }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <BaseSelect
                      style={{ marginBottom: "10px" }}
                      name="answer_one"
                      label="Do you have any health insuarance?"
                      options={question_one}
                    />
                  </Grid>

                  {state.openFormTwo === true ? (
                    <>
                      <Grid item xs={12}>
                        <BaseSelect
                          style={{ marginBottom: "10px" }}
                          name="answer_two"
                          label="Choose whether NHIF or others?"
                          options={question_two}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  {state.openFormThree === true ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          name="nhif_card"
                          label="Enter NHIF card number to validate your card"
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}

                  {state.statusCode === 200 ? (
                    <>
                      <h5>
                        Your NHIF status is{" "}
                        <span
                          style={{
                            color: "green",
                            fontSize: "18px",
                          }}
                        >
                          "{state.description}"
                        </span>
                      </h5>
                      <Formik
                        initialValues={INITIAL_STATE}
                        onSubmit={(helpers, values) =>
                          moveNextStageCheck(values, helpers)
                        }
                      >
                        {({ isValid, dirty }) => (
                          <Form className="form-inline">
                            <>
                              <button
                                style={{ marginTop: "20px" }}
                                className="btn btn-round  btn-md btn-primary form-inline-button"
                                type="submit"
                                name="submit"
                              >
                                Continue to next stage
                              </button>
                            </>
                            <br />
                          </Form>
                        )}
                      </Formik>
                    </>
                  ) : (
                    <>
                      {state.isStudentCard === 1 && state.statusCode !== 200 ? (
                        <>
                          <h5>
                            Your NHIF status is{" "}
                            <span
                              style={{
                                color: "green",
                                fontSize: "18px",
                              }}
                            >
                              "{state.description}"
                            </span>
                          </h5>
                          <RenewNhifCard cardNo={state.cardNo} />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      disabled={!dirty || !isValid}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Formik
            initialValues={INITIAL_STATE}
            onSubmit={(helpers, values) => moveNextStageCheck(values, helpers)}
          >
            {({ isValid, dirty }) => (
              <Form className="form-inline">
                <>
                  {/* <button
                    style={{ marginTop: "20px" }}
                    className="btn btn-round  btn-md btn-primary form-inline-button"
                    type="submit"
                    name="submit"
                  >
                    Skip this step
                  </button> */}
                </>
                <br />
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <BaseDialog
        fullWidth
        maxWidth={"md"}
        openDialog={state.submitDetailsToNhif}
        setOpenDialog={() => closeDialog()}
        title="SUBMIT YOUR DETAILS TO NHIF FOR REGISTRATION"
      >
        <SubmitDetailsToNhif />
      </BaseDialog>

      <ConfirmDialog
        open={state.openConfirmDialog}
        title="Card Verification Status"
        text={state.description}
      >
        <Button autoFocus onClick={closeConfirmDialog} color="primary">
          Cancel
        </Button>
      </ConfirmDialog>
    </>
  );
};

export default ResponseForm;
