import moment from "moment";

const toCurrency = (amount) => {
  const val = (amount / 1).toFixed(2).replace(".", ".");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const toDate = (date, format = "DD/MM/YYYY") =>
  format ? moment(date).format(format) : moment(date).format("DD/MM/YYYY");
//format ? moment(date).format(format) : moment(date).format("Do MMMM YYYY");

export { toCurrency, toDate };
