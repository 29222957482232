import React from "react";

const Slot = ({ children, otherProps })  => <span{...otherProps}>{children}</span>

const getSlots = ( names, children) =>  {
  return names.map(name => {
    let slot = null;
    React.Children.forEach(children, child => {
      if (!React.isValidElement(child)) {
        return;
      }
      if (child.type === Slot && (child.props).name === name) {
        slot = React.cloneElement(child);
      }
    });
    return slot;
  });
}

export { Slot, getSlots }
