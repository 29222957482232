import React, { Component } from "react";
import { Container, Alert } from "reactstrap";
import ApplicantProfile from "./ApplicantProfile";
import PersonalDetails from "./PersonalDetails";
import EditPersonalDetails from "./EditPersonalDetails";
import api from "../../../util/api";
import params from "../../../util/api_routes/params";

class Personal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [{ title: "Dashboard", link: "#" }],
      data: [],
      next_0f_kin_data: [],
      firstname: "",
      stage: parseInt(localStorage.getItem("stageid")),
    };
  }
  componentDidMount() {
    this.getApplicantProfile();
    this.getNextofKinInformation();
    // console.log(parseInt(localStorage.getItem("stageid")));
  }
  getApplicantProfile = () => {
    this.setState({ data: JSON.parse(localStorage.getItem("authUser")) });
    // this.setState({ data: [{ firstname: "sham" }, { middlename: "sule" }] });
  };

  getNextofKinInformation = async () => {
    this.setState({
      info_loading: true,
    });
    await api
      .get(params.NEXT_OF_KIN_LIST + localStorage.getItem("id"))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              next_0f_kin_data: res.data.data,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
            // console.log(this.state.next_0f_kin_data[0].fullname);
          }
        } else {
          this.setState({
            next_0f_kin_data: [],
            info_loading: false,
            network_error: true,
            filling_arrow: "right",
          });
        }
      })
      .catch((error) => {
        this.setState({ network_error: true, info_loading: false });
      });
  };

  render() {
    console.log(parseInt(localStorage.getItem("stageid")));
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.stage < 11 ? (
              <>
                {" "}
                {/* <div className="page-content"> */}
                <div className="card">
                  <div className="card-header">
                    <div className="float-left card-header-title">
                      {/* Completed */}
                    </div>
                    <div className="float-right card-header-title">
                      Application Number : {localStorage.getItem("id")}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="block">
                      <div className="alert alert-warning">
                        {/* <Alert severity="warning"> */}
                        Please make payment first
                        {/* {console.log(this.state.stage)} */}
                        {/* </Alert> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}
              </>
            ) : (
              <>
                {this.state.data !== null ? (
                  <EditPersonalDetails
                    data={this.state.data}
                    kindata={this.state.next_0f_kin_data}
                  />
                ) : (
                  <PersonalDetails />
                )}
              </>
            )}
          </Container>
          {/* {console.log(this.state.data[0].firstname)} */}
        </div>
      </React.Fragment>
    );
  }
}

export default Personal;
