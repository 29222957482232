export default function localStorageSetup_Login(data) {
  //localStorage.setItem("token", JSON.stringify(data.token));
  localStorage.setItem("token", JSON.stringify(data.details.tokenValue));
  localStorage.setItem(
    "authenticatedUser",
    JSON.stringify(data.userAuthentication.principal.student)
  );
  localStorage.setItem(
    "registrationNo",
    data.userAuthentication.principal.student.registrationNo
  );
  localStorage.setItem(
    "admission",
    data.userAuthentication.principal.student.admission.admno
  );
  localStorage.setItem(
    "intakeid",
    data.userAuthentication.principal.student.admission.intake.id
  );
  localStorage.setItem(
    "intake",
    data.userAuthentication.principal.student.admission.intake.intake
  );
  localStorage.setItem(
    "applStatus",
    data.userAuthentication.principal.student.admission.intake.applStatus
  );
  localStorage.setItem(
    "campusName",
    data.userAuthentication.principal.student.admission.campus.campusName
  );
  localStorage.setItem(
    "campusId",
    data.userAuthentication.principal.student.admission.campus.id
  );
  localStorage.setItem(
    "programId",
    data.userAuthentication.principal.student.admission.program.id
  );
  localStorage.setItem(
    "programName",
    data.userAuthentication.principal.student.admission.program.name
  );
  localStorage.setItem(
    "programCode",
    data.userAuthentication.principal.student.admission.program.code
  );
  localStorage.setItem(
    "duration",
    data.userAuthentication.principal.student.admission.program.duration
  );
  localStorage.setItem(
    "campusId",
    data.userAuthentication.principal.student.admission.campus.id
  );
  localStorage.setItem(
    "regYear",
    data.userAuthentication.principal.student.regYear
  );
  localStorage.setItem(
    "yearOfStudy",
    data.userAuthentication.principal.student.yearOfStudy
  );
  localStorage.setItem(
    "userName",
    data.userAuthentication.principal.student.userName
  );

  return true;
}
