import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: { minWidth: "650px", width: "auto" },
  divider: { padding: 0 },
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "& div": {
      color: "white",
    },
  },
  dialogWrapper: {
    padding: theme.spacing(0),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  icon: {
    cursor: "pointer",
  },
}));

const ConfirmDialog = React.forwardRef(
  ({ children, setOpen, ...props }, ref) => {
    const classes = useStyles();
    const [state, setState] = React.useState({
      open: false,
      content: "",
      input: false,
    });
    const callbacks = React.useRef({ onCancel: [], onConfirm: [] });

    React.useImperativeHandle(
      ref,
      () => {
        const actions = {
          onConfirm: (clb) => {
            if (clb) callbacks.current.onConfirm.push(clb);
            return actions;
          },
          onCancel: (clb) => {
            if (clb) callbacks.current.onCancel.push(clb);
            return actions;
          },
        };
        return {
          confirm: (title, text = "", other) => {
            setState((v) => ({
              ...v,
              title,
              text,
              open: true,
              input: other?.input ?? false,
              content: "",
            }));
            return actions;
          },
          close: () => {
            setState((v) => ({
              ...v,
              title: "",
              text: "",
              content: "",
              open: false,
              input: false,
            }));
            callbacks.current.onConfirm = [];
            callbacks.current.onCancel = [];
          },
        };
      },
      []
    );
    React.useEffect(() => {
      if (props.open === undefined) return;
      callbacks.current.onConfirm = [];
      callbacks.current.onCancel = [];
      setState((v) => ({
        ...v,
        title: props.title,
        content: "",
        text: props.text,
        open: props.open,
      }));
    }, [props.title, props.text, props.open]);
    const onCancel = () => {
      const content = state.content;
      setState((v) => ({ ...v, open: false, content: "", input: false }));
      callbacks.current.onCancel.map((c) => c(content));
    };
    const onConfirm = () => {
      const content = state.content;
      setState((v) => ({ ...v, open: false, content: "", input: false }));
      callbacks.current.onConfirm.map((c) => c(content));
    };
    return (
      <Dialog
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={state.open}
        {...props}
      >
        <DialogTitle className={classes.root} id="confirmation-dialog-title">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {state.title}
          </Typography>
        </DialogTitle>
        {state.text && <DialogContent>{state.text}</DialogContent>}
        {state.input && (
          <TextField
            placeholder="Approval Message"
            multiline
            minRows={4}
            value={state.content}
            onChange={(e) => {
              const _value = e.target.value;
              setState((v) => ({ ...v, content: _value }));
            }}
            variant="filled"
            fullWidth
            style={{ padding: "10px", minWidth: 500 }}
          />
        )}
        {children && <DialogActions>{children}</DialogActions>}
        {!children && (
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
);
export function useConfirmDialog() {
  const ref = React.useRef();
  const confirm = (title, text, other) => {
    if (!ref.current)
      console.error("useConfirmDialog: ConfirmDialog ref not set");
    return ref.current?.confirm(title, text, other);
  };
  const close = () => {
    if (!ref.current)
      console.error("useConfirmDialog: ConfirmDialog ref not set");
    return ref.current?.close();
  };
  return {
    ref,
    confirm,
    close,
  };
}

export default ConfirmDialog;
