import React, { Component } from "react";
import HighSchoolDisplay from "./highSchoolDisplay";
import params from "../../util/api_routes/params";
import api from "../../util/api";

class Alevel extends Component {
  constructor(props) {
    super(props);
    this.state = {      
      school_data_alevel: [],
      info_loading: null,
      network_error: false,
      filling_arrow: null,
    };
  }
  componentDidMount() {
    this.getSecondarySchools();
  }

  getSecondarySchools = async () => {
    this.setState({ info_loading: true });
    await api
      .get(params.ALEVEL_LIST + parseInt(localStorage.getItem("admission")))
      .then((res) => {
        if (res.status === 200) {
          if (res.data.stsCode === "0000") {
            this.setState({
              school_data_alevel: res.data.data,
              info_loading: false,
              network_error: false,
              filling_arrow: "right",
            });
          }
        } else {
          this.setState({
            school_data_alevel: [],
            info_loading: false,
            network_error: false,
            filling_arrow: "right",
          });
        }
      })
      .catch((err) => {
        this.setState({
          info_loading: false,
          network_error: true,
        });
      });
  };
 
  render() {
    return (
    <>
       <div >      
          <div>
          {this.state.school_data_alevel.length ? (
            <>
           <h6>A-level Secondary Education</h6>
           <HighSchoolDisplay
            high_school={this.state.school_data_alevel}
          />   
          </>
          ):<>You have no A level records</>}    
          </div>
       </div> 
    </>
    );
  }
}

export default Alevel;