import React from "react";
import MUIButton from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Button = ({ children, ...otherProps }) => {
  const buttonConfig = {
    variant: "contained",
    color: "primary",
    size: "medium",
    fullWidth: true,
    ...otherProps,
  };

  return <MUIButton {...buttonConfig}>{children}</MUIButton>;
};

Button.defaultProps = {
  variant: "contained",
  color: "primary",
  size: "medium",
};

Button.propTypes = {
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "success",
    "error",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func,
};
export function ButtonLink({ title, to, ...props }) {
  return (
    <Link to={to} style={{ textDecoration: "underline !important" }} {...props}>
      {title}
    </Link>
  );
}

export default Button;
