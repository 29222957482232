import { HIDE_SNACKBAR, SHOW_SNACKBAR } from "../action-types";

export const showSnackbar = (payload) => {
  return (dispatch) => {
    dispatch({ type: SHOW_SNACKBAR, payload });
  };
};

export const hideSnackbar = (payload) => {
  return (dispatch) => {
    dispatch({ type: HIDE_SNACKBAR, payload });
  };
};
