import React from "react";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";

export const TEXT_FIELD_VARIANTS = {
  OUTLINED: "outlined",
  FILLED: "filled",
};

const BaseTextField = ({
  name,
  minDate,
  maxDate,
  onItemChange = undefined,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();

  const [field, meta] = useField(name);
  const isDate = otherProps.type === "date";

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configTextField = {
    ...field,
    ...otherProps,
    error: false,
    helperText: "",
    fullWidth: true,
    InputLabelProps: isDate ? { shrink: true } : undefined,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    configTextField.helperText = meta.error;
  }

  return (
    <TextField
      InputProps={{ inputProps: { min: minDate, max: maxDate } }}
      InputLabelProps={{ shrink: true }}
      {...configTextField}
    />
  );
};

BaseTextField.defaultProps = {
  type: "text",
  variant: "outlined",
};

BaseTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default BaseTextField;
